// Customizable Area Start
import React from "react";

import {
    Box,
    Typography,
    styled, Grid,
    Collapse,
    CircularProgress,
} from "@material-ui/core";

import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web"

import { ArrowForwardIos, Close, Add, ArrowBackIos } from "@material-ui/icons";

import PrivacyFaqController, {
    Props,
} from "./PrivacyFaqController";
import { notfound } from "./assets";

export default class Faq extends PrivacyFaqController {
    constructor(props: Props) {
        super(props);
    }
    renderNoFound() {
        if (this.state.queAnsData.length === 0 && this.state.loader === false) {
            return <>
                <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <Box style={{ width: "100%", maxWidth: "400px", }}>
                        <img src={notfound} style={{ width: "100%" }} alt="" />
                    </Box>
                    <PrivacyDes style={{ marginTop: "0" }}>No FAQ's found</PrivacyDes>
                </Box>
            </>
        } else {
            if (this.state.loader) {
                return <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <Box style={{ width: "100%", maxWidth: "400px" , height:"400px",display: "flex", justifyContent: "center", alignItems: "center",}}>
                        <CircularProgress />
                    </Box>
                </Box>

            }
        }
    }
    render() {
        return (
            <Box>
                <CustomNavbar>
                    <Navbar navigation={this.props.navigation} id={""} NaviagationPage={"FAQs"} />
                </CustomNavbar>
                <>
                    <NavbarMobile>
                        <ArrowBackIos data-test-id='btnBack' onClick={this.navigateSettingMobile} style={webStyle.backIcon} />
                        <NavbarTitle>FAQ</NavbarTitle>
                    </NavbarMobile>
                    <MainBoxFaq>
                        <Navigation>
                            <Typography data-test-id="navigateToHome" onClick={this.handleBack} style={{...webStyle.home ,cursor:'pointer'}}>Home</Typography>
                            {this.state.istokenPresent && <>
                                <ArrowForwardIos style={webStyle.forwardArrow} />
                                <Typography style={{...webStyle.home , cursor:'pointer'}} onClick={this.navigateSetting}>Settings</Typography>
                            </>}
                            <ArrowForwardIos style={webStyle.forwardArrow} />
                            <Typography style={webStyle.joblisting}>Frequently asked questions</Typography>
                        </Navigation>
                        <Heading>Frequently asked questions</Heading>
                        <PrivacyDes>
                            Have questions? We've got answers. Check out our frequently asked questions to learn more about Palmetto Childcare Connect, how it works, and how it can benefit you. If you don't find what you're looking for, feel free to reach out to our team for assistance.
                        </PrivacyDes>
                        <Content container spacing={4}>
                            <Grid item sm={12}>
                                <Box>
                                    {this.state.queAnsData.map((item) => (
                                        <Box style={{ ...webStyle.quesAns, background: this.state.openQuestionId == item.id ? '#F1F5F9' : '#F8F8F8' }} key={item.id}>
                                            <Box style={webStyle.quesBox}>
                                                <div style={webStyle.question} dangerouslySetInnerHTML={{__html :item.attributes.question}}></div>
                                                {this.state.openQuestionId == item.id ? (
                                                    <Close data-test-id="closeAnswer" onClick={this.closeAnswer} style={{cursor:'pointer',}}/>
                                                ) : (
                                                    <Add data-test-id="openAnswer" onClick={() => this.openAnswer(item.id)} style={{cursor:'pointer',}}/>
                                                )}
                                            </Box>
                                            <Collapse in={this.state.openQuestionId == item.id}>
                                                <div dangerouslySetInnerHTML={{__html:item.attributes.answer}} style={webStyle.answers}>
                                                    
                                                </div>
                                            </Collapse>
                                        </Box>
                                    ))}
                                    {this.renderNoFound()}
                                </Box>
                            </Grid>
                        </Content>
                    </MainBoxFaq>
                </>
                <CustomFooter>
                    <Footer navigation={this.props.navigation} id={""} NaviagationPage={""} />
                </CustomFooter>
            </Box>
        );
    }
}

const CustomNavbar = styled(Box)({
    "@media only screen and (max-width: 571px)": {display: 'none', }
})
const NavbarMobile = styled(Box)({
    display: 'none',"@media only screen and (max-width: 571px)": {
        display: 'flex',position: 'relative',backgroundColor: '#395169',padding: '15px 0 15px 20px',alignItems: 'center',
    },
});

const NavbarTitle = styled(Box)({
    fontSize: '16px', fontWeight: 600,lineHeight: '24px', top: '50%',fontFamily: 'Inter',left: '50%',color: '#FFFFFF',position: 'absolute',transform: 'translate(-50%, -50%)',
});
const CustomFooter = styled(Box)({
    "@media only screen and (max-width: 571px)": {display: 'none',}
})
const MainBoxFaq = styled(Box)({
    background: "#F8F8F8", position: "relative",  padding: "140px 222px 120px 216px",
    "@media only screen and (max-width: 1330px)": {paddingLeft: 200, paddingRight: 200,paddingTop: 100,},
    "@media only screen and (max-width: 1235px)": {paddingRight: 180,paddingTop: 100,paddingLeft: 180,},
    "@media only screen and (max-width: 1100px)": {paddingRight: 150,paddingTop: 100,paddingLeft: 150,},
    "@media only screen and (max-width: 959px)": {padding: 100,paddingTop: 100,},
    "@media only screen and (max-width: 664px)": {paddingTop: 100,padding: 37,},
    "@media only screen and (max-width: 571px)": {
        paddingRight: "35px",paddingTop: 20,paddingLeft: "35px"}
})
const Navigation = styled(Box)({
    display: "flex",position: "absolute" as "absolute", top: 32, gap: "10px",left: 114,
    "@media only screen and (max-width: 571px)": {display: 'none',}
})
const Heading = styled(Typography)({
    color: '#1E293B', fontFamily: "Inter",fontWeight: 700,fontSize: '30px', marginBottom: 24,lineHeight: '40px',
    "@media only screen and (max-width: 571px)": {display: 'none',}
})
const PrivacyDes = styled(Typography)({
    fontSize: '16px',color: '#334155',lineHeight: '25.6px',fontFamily: 'Inter',fontWeight: 400, marginTop: '24px',
    "@media only screen and (max-width: 620px)": {fontSize: '14px',marginTop: '20px',
    },
})
const Content = styled(Grid)({marginTop: "10px"})
const webStyle = {
    joblisting: { color: "#0F172A", fontFamily: "Inter", fontWeight: 400,fontSize: "14px",lineHeight: "22px",
    },
    home: { color: "#475569",fontSize: "14px",fontFamily: "Inter",fontWeight: 400,lineHeight: "22px",},
    forwardArrow: {color: "#475569", marginTop: 4,height: "15px",
    },
    roleBox: {
        border: '1px solid #E2E8F0',height: '56px',width: '86%',borderRadius: '8px',marginBottom: '16px',textAlign: 'center',color: '#1E293B',fontFamily: 'Inter',fontSize: '16px',fontWeight: 600,display: 'flex',justifyContent: 'center',alignItems: 'center'
    },
    showingHeading: {
        color: '#1E293B',fontFamily: 'Inter',fontSize: '24px',fontWeight: 400,lineHeight: '32px',paddingLeft: "24px"
    },
    quesAns: {padding: '24px', borderRadius: '24px',marginTop: '24px'
    },
    quesBox: {display: 'flex', gap: '32px',justifyContent: 'space-between'},
    answers: {color: '#334155',marginTop: '16px',fontFamily: 'Inter',fontSize: '16px',fontWeight: 400,lineHeight: '24px',},
    question: {color: '#1E293B',fontFamily: 'Inter',fontSize: '16px', fontWeight: 600,lineHeight: '24px',},
    backIcon: {color: '#fff'}
}
// Customizable Area End

