import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    TextField,
    Typography,
    styled
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';

interface SurveyFormProps {
    handleSurveyNotificationClose: () => void;
    isSurveyNotificationOpen: boolean;
    handleSurveySubmission: (data: any) => void;
    role: number;
    handleTextFieldForTeacherChange: (value: string, name: string) => void;
    handleTextFieldForAdminChange: (value: string, name: string) => void;
    isFormSubmited: boolean;
    successMessage: string;
    ErrorMessage: string;
    formIsEmpty: boolean;
    adminFormData: any;
    teacherFormData:any
    name:any
}

interface InputHOCProps {
    handleChange: (value: string, name: string) => void;
    name: string;
    question: string;
    customError: string;
    errorMessage: string;
    value:any;
    setError: (name: string, error: string) => void;
}

const InputHOC = ({
    handleChange,
    name,
    question,
    customError,
    errorMessage,
    setError,
    value
}: InputHOCProps) => {
    const [error, setLocalError] = useState('');

    const handleInputValidationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        console.log( value);
        
        if (value.trim() === "") {
            setLocalError(customError);
            setError(name, customError);
            handleChange("", name);
        } else {
            setLocalError('');
            setError(name, '');
            handleChange(value, name);
        }
    };

    useEffect(() => {
        setLocalError(errorMessage);
    }, [errorMessage]);

    return (
        <>
            <Typography style={webStyle.textfield}>{question}</Typography>
            <TextField
                fullWidth
                variant="outlined"
                error={!!error}
                name={name}
                value={value}
                onChange={handleInputValidationChange}
            />
            <Typography style={webStyle.errorText}>
                {error}
            </Typography>
        </>
    );
};

export default function SurveyForm({
    isSurveyNotificationOpen,
    handleSurveySubmission,
    handleSurveyNotificationClose,
    role,
    handleTextFieldForTeacherChange,
    handleTextFieldForAdminChange,
    adminFormData,
    teacherFormData,
    isFormSubmited,
    successMessage,
    name
}: SurveyFormProps) {

    const [errors, setErrors] = useState<any>({});    
    const validateAllFields = () => {
        let newErrors: any = {};
        if (role === 1) {
            if (!adminFormData.shift) newErrors.shift = "Please enter your shift";
            if (!adminFormData.position) newErrors.position = "Please enter your position";
            if (!adminFormData.hire_again) newErrors.hire_again = "Please enter yes or no answer";
            if (!adminFormData.feedback) newErrors.feedback = "Please enter your feedback";
        } else {
            if (!teacherFormData.shift) newErrors.shift = "Please enter your shift";
            if (!teacherFormData.ages_worked_with) newErrors.ages_worked_with = "Please enter the ages you worked with";
            if (!teacherFormData.position) newErrors.position = "Please enter your position";
            if (!teacherFormData.welcoming_program) newErrors.welcoming_program = "Please enter if the program was welcoming and helpful";
            if (!teacherFormData.prepared_for_job) newErrors.prepared_for_job = "Please enter if you felt prepared";
            if (!teacherFormData.return_to_program) newErrors.return_to_program = "Please enter if you would like to return";
            if (!teacherFormData.feedback) newErrors.feedback = "Please enter your feedback";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSetError = (name: string, error: string) => {
        setErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSubmit = () => {
        if (validateAllFields()) {
            handleSurveySubmission(role === 1 ? adminFormData : teacherFormData);
        }
    };

    return (
        <>
            {role === 1 &&
                <CustomDialog open={isSurveyNotificationOpen}>
                    <Box style={webStyle.dialogHeader}>
                        <Typography style={webStyle.headerHeading}>Job Completion Survey - {name}</Typography>
                    </Box>
                    <Box style={webStyle.subBox}>
                        <Typography style={{...webStyle.kindlyFill , marginBottom:"15px"}}>We greatly value your feedback! Please take a moment to share your thoughts on the completion of your recent Substitute Teacher.</Typography>
                        <InputHOC
                            handleChange={handleTextFieldForAdminChange}
                            name="shift"
                            question="What Shift did today’s Substitute complete?"
                            customError={"Please enter your shift"}
                            errorMessage={errors.shift}
                            setError={handleSetError}
                            value={adminFormData.shift}
                        />
                        <InputHOC
                            handleChange={handleTextFieldForAdminChange}
                            name="position"
                            question="What Position?"
                            customError={"Please enter your position"}
                            errorMessage={errors.position}
                            setError={handleSetError}
                            value={adminFormData.position}
                        />
                        <InputHOC
                            handleChange={handleTextFieldForAdminChange}
                            name="hire_again"
                            question="Would you hire this Substitute again? If not, please explain."
                            customError={"Please enter yes or no answer"}
                            errorMessage={errors.hire_again}
                            setError={handleSetError}
                            value={adminFormData.hire_again}
                        />
                        <InputHOC
                            handleChange={handleTextFieldForAdminChange}
                            name="feedback"
                            question="Any Feedback?"
                            customError={"Please enter your feedback"}
                            errorMessage={errors.feedback}
                            setError={handleSetError}
                            value={adminFormData.feedback}
                        />
                    </Box>
                    <Box style={webStyle.buttonbox}>
                        <Button style={webStyle.submitBtn} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Box>
                </CustomDialog>}
            
            {role === 2 &&
                <CustomDialog open={isSurveyNotificationOpen}>
                    <Box style={webStyle.dialogHeader}>
                        <Typography style={webStyle.headerHeading}>Job Completion Survey - {name}</Typography>
                    </Box>
                    <Box style={webStyle.subBox}>
                        <Typography style={{...webStyle.kindlyFill ,marginBottom:"15px"}}>We greatly value your feedback! Please take a moment to share your thoughts on the completion of your recent teaching job.</Typography>
                        <InputHOC
                            handleChange={handleTextFieldForTeacherChange}
                            name="shift"
                            question="What shift did you take today?"
                            customError={"Please enter your shift"}
                            errorMessage={errors.shift}
                            setError={handleSetError}
                            value={teacherFormData.shift}
                        />
                        <InputHOC
                            handleChange={handleTextFieldForTeacherChange}
                            name="ages_worked_with"
                            question="What ages did you work with most of today?"
                            customError={"Please enter the ages you worked with"}
                            errorMessage={errors.ages_worked_with}
                            setError={handleSetError}
                            value={teacherFormData.ages_worked_with}
                        />
                        <InputHOC
                            handleChange={handleTextFieldForTeacherChange}
                            name="position"
                            question="What position were you in? (Lead Teacher, Assistant Teacher, Support Staff, Admin)"
                            customError={"Please enter your position"}
                            errorMessage={errors.position}
                            setError={handleSetError}
                            value={teacherFormData.position}
                        />
                        <InputHOC
                            handleChange={handleTextFieldForTeacherChange}
                            name="welcoming_program"
                            question="Was the program welcoming and helpful?"
                            customError={"Please enter if the program was welcoming and helpful"}
                            errorMessage={errors.welcoming_program}
                            setError={handleSetError}
                            value={teacherFormData.welcoming_program}

                        />
                        <InputHOC
                            handleChange={handleTextFieldForTeacherChange}
                            name="prepared_for_job"
                            question="Did you feel prepared for the job you were asked to do? If not, please explain."
                            customError={"Please enter if you felt prepared"}
                            errorMessage={errors.prepared_for_job}
                            setError={handleSetError}
                            value={teacherFormData.prepared_for_job}

                        />
                        <InputHOC
                            handleChange={handleTextFieldForTeacherChange}
                            name="return_to_program"
                            question="Would you like to return to this program?"
                            customError={"Please enter if you would like to return"}
                            errorMessage={errors.return_to_program}
                            setError={handleSetError}
                            value={teacherFormData.return_to_program}

                        />
                        <InputHOC
                            handleChange={handleTextFieldForTeacherChange}
                            name="feedback"
                            question="Any feedback?"
                            customError={"Please enter your feedback"}
                            errorMessage={errors.feedback}
                            setError={handleSetError}
                            value={teacherFormData.feedback}

                        />
                    </Box>
                    <Box style={webStyle.buttonbox}>
                        <Button style={webStyle.submitBtn} onClick={handleSubmit} data-test-id="submitTestId">
                            Submit
                        </Button>
                    </Box>
                </CustomDialog>
            }
             <CustomDialog open={isFormSubmited}>
                <Box style={webStyle.dialogHeader2}>
                    <Typography style={webStyle.headerHeading}>Survey Submitted.</Typography>
                    <ClearIcon onClick={handleSurveyNotificationClose}/>
                </Box>
                <Box style={webStyle.subBox2}>
                    <Typography style={webStyle.kindlyFill}>{successMessage}</Typography>
                </Box>
                <Box style={webStyle.buttonbox2}>
                    <Button style={webStyle.cancelBtn} onClick={handleSurveyNotificationClose}>
                        Cancel
                    </Button>
                </Box>
            </CustomDialog>
        </>
    );
}



const CustomDialog = styled(Dialog)({
    borderRadius: '16px',
    '& .MuiDialog-paperWidthSm': {
        minWidth: '650px',
        borderRadius: '16px',
        "@media only screen and (max-width: 816px)": {
            minWidth: '500px'
        },
        "@media only screen and (max-width: 553px)": {
            minWidth: '300px'
        },
    }
})


const webStyle = {
    option: {
        display: "flex",
        gap: "40px",
        alignItems: "center"
    },
    textstyle: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        padding: "8px 6px",
        cursor: "pointer"
    },
    loginBtn: {
        color: "#395169",
        border: "1px solid #395169",
        borderRadius: "8px",
        padding: "10px 32px",
        fontFamily: "Inter",
        textTransform: "none" as "none",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    signBtn: {
        color: "#FFFFFF",
        border: "1px solid #395169",
        padding: "10px 32px",
        borderRadius: "8px",
        background: "#395169",
        fontFamily: "Inter",
        fontSize: "14px",
        textTransform: "none" as "none",
        fontWeight: 700,
        lineHeight: "22px",
    },
    profile: {
        display: "flex",
        gap: 12,
        paddingLeft: "40px",
        borderLeft: "1px solid #E2E8F0"
    },
    name: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
    },
    menuList: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    dialogHeader: {
        padding: '24px 16px 24px 40px',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E2E8F0'
    },
    dialogHeader2: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E2E8F0',
        padding: '24px 16px 24px 40px',
        maxHeight: "80px"
    },
    headerHeading: {
        color: '#0F172A',
        fontFamily: 'Inter',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
    },
    subBox: {
        padding: '32px 40px',
        borderBottom: '1px solid #E2E8F0'
    },
    subBox2: {
        padding: '37px 40px 16px 40px',
        maxHeight: "78px",
        borderBottom: '1px solid #E2E8F0',
    },
    kindlyFill: {
        color: '#334155',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    textfield: {
        color: '#64748B',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        marginBottom: '6px'
    },
    buttonbox: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 40px'
    },
    buttonbox2: {
        justifyContent: 'space-between',
        display: 'flex',
        maxHeight :"104px",
        padding: '24px 40px',
    },
    cancelBtn: {
        color: '#395169',
        fontFamily: 'Inter',
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '24px',
        width: '100%',
        background: '#fff',
        textTransform: 'none' as 'none',
        height: '56px',
        cursor: 'pointer',
    },
    submitBtn: {
        color: '#fff',
        background: '#395169',
        width: '100%',
        fontFamily: 'Inter',
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '24px',
        cursor: 'pointer',
        textTransform: 'none' as 'none',
        height: '56px'
    },
    deleteBox: {
        padding: '60px 40px 32px 40px',
        position: 'relative' as 'relative'
    },
    deleteClose: {
        position: 'absolute' as 'absolute',
        top: 20,
        right: 20,
        cursor: 'pointer'
    },
    errorText: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        color: '#DC2626',
        marginTop: '4px',
    }
}

export { InputHOC }