// Customizable Area Start
import React from "react";
import {
  TextField,
  Grid,
  Typography,
  Button,
  styled,
  ThemeProvider,
  Checkbox,
  Slider,
  Box,
  IconButton,
  Chip,
  MenuItem,
  Input,
  Menu,
  Popover,
  CircularProgress,
} from "@material-ui/core";
import {
  image,
  description,
  location,
  unchecked,
  checked,
  calendar,
  noResult,
  logo,
  state,
  profilePic,
  notFound,
  pcc
} from "./assets";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ClearIcon from "@material-ui/icons/Clear";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import MenuIcon from '@material-ui/icons/Menu'
import JobListingController, { CardsDetails ,Pagination} from "./JobListingController";
import Navbar from "../../../components/src/Navbar.web";
import CustomePagination from "../../..//components/src/CustomPagination.web";
import Footer from "../../../components/src/Footer.web";
import moment from "moment";
import "moment/locale/en-gb";
import { enGB } from "date-fns/locale";
import DrawerComponent from "../../../components/src/Drawer.web";

moment.locale("en-gb"); 

class JobListing extends JobListingController {
  static JobListing: JobListing;
 
  headingBox = () => {
    const { anchorEl , selectedSortItem} = this.state    
    const open = Boolean(anchorEl)
    return (
      <div style={webStyle.heading}>
        <ShowingHeading>
          Showing{" "}
          <span style={{ fontWeight: 700 }}>
          {this.renderTitle()}
          </span>
        </ShowingHeading>
        {this.state.isFullView || this.state.isSearch ? (
          <>
          <Box style={{ display: "flex", gap: 5 , cursor:"pointer"}} data-test-id="handleSortDropDownClick" onClick={this.handleSortDropDownClick}  >
            <Typography style={webStyle.view}  >Sort by: {selectedSortItem ? selectedSortItem : "Relevance"}</Typography>
            <KeyboardArrowDownIcon style={{ marginTop: 1 }}/>
          </Box>
          <Dropdown
              anchorEl={anchorEl}
              open={open}
              onClose={this.handleClose}
              data-test-id="dropdown-test-id"
            >
              <MenuItem style={webStyle.menuList} onClick={this.handleListingRelavance} id="Relevance">Relevance</MenuItem>
              <MenuItem style={webStyle.menuList} onClick={this.handleListingMostRecentData} id="MostRecent">Most Recent</MenuItem>
              <MenuItem style={webStyle.menuList} onClick={this.handleListingLeastRecentData} id='LeastRecent'>Least Recent</MenuItem>
            </Dropdown>
          </>
        ) : (
          <Typography style={webStyle.zerotext}>0 results</Typography>
        )}
      </div>
    );
  };

  noFoundTypo = () => {
    return (
      <Typography style={webStyle.subLine}>
        {!this.state.isFilterOptionEmpty ? (
          <>Try searching using another keyword</>
        ) : (
          <>
            It seems that none of the options match your search and filter
            criteria.
          </>
        )}
      </Typography>
    );
  };
  renderpagination = () =>{
    const  {s_loader, m_loader , r_loader , y_loader , nr_loader, ny_loader , t_loader}=this.state
    return (this?.state?.isPaginate && this.state.isToken && (!s_loader && !m_loader&& !r_loader && !y_loader && 
      !t_loader && !nr_loader && !ny_loader
    ))&&
      <CustomePagination
      onchange={this.handlePaginationChange} 
      current_page={this.state.current_page}
      total_page={this.state.total_pages}
      total_count ={this.state.total_count}
      /> 
    
  }

  activeFilters = ()=>{
    const {
      bachelorCheck, associateCheck, highSchoolCheck, certificateCheck,
      value, selectedState, textFieldValueDocEnd,textFieldValueDocStart
    } = this.state;
    const selectedItems: string[] = [];
    if (bachelorCheck) selectedItems.push("Bachelor's degree");
    if (associateCheck) selectedItems.push("Associate's degree");
    if (highSchoolCheck) selectedItems.push('High school diploma or equivalent');
    if (certificateCheck) selectedItems.push('Certification in substitute teaching');
    if (value !== 0) selectedItems.push(`Exp - ${value} Yrs`);
    if (selectedState) selectedItems.push(selectedState);
    if (textFieldValueDocStart) selectedItems.push(`From - ${textFieldValueDocStart}`);
    if (textFieldValueDocEnd) selectedItems.push(`To - ${textFieldValueDocEnd}`);
    return selectedItems
  }


  renderTitle = () => {
    const screen:string =this.state.nameOfPageWhichShowingFull||this.state.filteredItem
    if(this.state.isSearch && this.state.enteredText){
      return `${this.state.enteredText}`
    }
    else if (screen === 'Matching your profile') {
      return 'Matching your profile'
    }
    else if(screen === 'Recently added'){
      return 'Recently added'
    }
    else if(screen === 'You may be interested in'){
      return 'You may be interested in'
    }
    else if(screen === 'Top Candidates'){
      return 'Top Candidates'
    }
    
  }
  
  showSelectedFilter = (item:string[]) =>{
    return <>
    
      {item?.map((item: string, index) => (
        <ChipItem
          key={index}
          label={item}
          deleteIcon={<ClearIcon style={{ color: '#334155', height: "17px" }} />}
          style={{ marginRight: '8px', marginTop: '8px' }}
          data-test-id="handleChipDelete"
          onDelete={() => {
            this.handleChipDelete(item)
          }}
        />
      ))}
      {item?.length > 0 && (
        <Chip
          label="Clear All"
          onClick={this.clearAll}
          data-test-id="clearAll"
          style={webStyle.clearall}
        />
      )}
    </>
  }

  hasNoToken = () => {
    const { nameOfPageWhichShowingFull, noLoginRecentJobs,  noLoginInterestedJobs,isSearchedClicked, nr_loader ,ny_loader} = this.state;
    
    if ( isSearchedClicked) { return null; }

    const renderSection = (jobs: any[],
      name: string, handleView: (dataTestId: string) => void, dataTestId: string , loader:boolean) => {
      const isFullView = nameOfPageWhichShowingFull === name;
      const displayStyle = isFullView || nameOfPageWhichShowingFull === "" ? "block" : "none";
      const headingStyle = name === "You may be interested in" ? { ...webStyle.heading, marginTop: "64px"  ,marginBottom : "0px"} : {...webStyle.heading , marginBottom : "0px"};
      const job = this.state.isFullView 
                                      ? jobs 
                                      : jobs.slice(0, 5)
      return (
        <div style={{ display: displayStyle }}>
          <div style={headingStyle}>
            {!nameOfPageWhichShowingFull && <Typography style={webStyle.recently}>{name}</Typography>}
            {isFullView ? (this.headingBox()) : (
              <div style={{ display: "flex" }}>
                <Typography style={webStyle.view} data-test-id={dataTestId} onClick={() => handleView(dataTestId)}>View all</Typography>
                <ArrowForwardIosIcon style={webStyle.forwardArrow} />
              </div>
            )}
          </div> {this.renderHasTokenScreen(job ,loader)} </div>
      );
    };

    return (
      <>
        {renderSection(noLoginRecentJobs, "Recently added", this.handleViewForRecentlyAddedJobs, "recentlyadded" , nr_loader) }
        {renderSection(noLoginInterestedJobs, "You may be interested in", this.handleViewInterestedJobs, "youmayintrestedin" , ny_loader)}
      </>
    );
  };

  renderNavigation(){
    return (
      <>
       {(this.state.isSearch || this.state.isFullView) &&(
      <div style={webStyle.navigation}>
        <Typography style={webStyle.home}
         onClick={async () => {
          await this.clearEnteredText()
           this.resetData()
        }}
        data-test-id='renderNavigation'
        >Home</Typography>
        <ArrowForwardIosIcon style={webStyle.forwardArrow} />
        {this.state.searchedItemText.length == 0 ? (
          <Typography style={webStyle.joblisting}>
         {this.listingTitle()}
          </Typography>
        ) : (
          <Typography style={webStyle.joblisting}>
            Search results
          </Typography>
        )}
      </div>
    )}
      </>
    )
   }

   renderImage = (img:string)=>{
    if (img) {
      return img
    }else{
      return pcc
    }
   }
  renderApiData = (data: CardsDetails[]) => {
    const dataToRender = data;

    return (
      <>
        {dataToRender?.length > 0 &&
          dataToRender.map((item) => {
            return (
              <div style={{position:'relative'}}>
              <CustomCard>
                <div style={{ display: "flex", justifyContent: "space-between", position:'relative' }}>
                  <Typography style={webStyle.firstdiv}>
                    {item.attributes?.job_title}
                  </Typography>
                    
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Typography style={{ ...webStyle.seconddiv, color: "#64748B" }}>Posted:</Typography>
                    <Typography style={webStyle.typeStyle}>{item.attributes?.created_at}</Typography>
                  </div>
                </div>
                <div style={webStyle.customdivStyle}>
                  <Typography style={webStyle.thirddiv}>{item.attributes?.experience_required}</Typography>
                  <Typography style={webStyle.thirddiv}>{item.attributes?.education_level}</Typography>
                </div>
                <div 
                style={{...webStyle.ContainerStyle ,alignItems:"center"}}>
                    <img  data-test-id='renderImage' src={this.renderImage(item.attributes?.school_logo?.url)}  onError={({currentTarget})=>currentTarget.src = pcc} style={{width:"100%" ,maxWidth:"44px" ,height:"44px" , borderRadius:"50%" , border:"2px solid #F8F8F8" , objectFit:"cover"}} />
                    <div>
                    <Typography style={webStyle.seconddiv}>
                      {item.attributes?.organization}
                      </Typography>
                    <Typography style={{ ...webStyle.seconddiv, color: "#64748B" }} >
                      {item.attributes?.posted_by} . School admin</Typography>
                  </div>
                </div>
                <div style={{ display: "flex", gap: "10px", marginTop: "24px"}}>
                  <div> <img src={description} />
                  </div>
                  <div> <Typography style={{ ...webStyle.seconddiv, marginTop:"-12px", color: "#475569" }}>
                      <div style={{wordBreak:"break-all" , textWrap:"wrap" , wordWrap:"break-word"}}
                       dangerouslySetInnerHTML={{ __html: this.shortString(item.attributes?.job_description) }} /></Typography>
                  </div>
                </div>
                <Grid container style={{...webStyle.newdiv , marginTop:'0px' , paddingTop:"12px" , gap :"10px"}}>
                  <Grid item sm={6}>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div style={{ width: "20.67px", height: "16.67px" }}>
                        <img src={location} style={{ width: "100%" }} />
                      </div>
                      <Typography style={webStyle.anotherdiv}>{item.attributes?.city}, {item.attributes?.state}, {item.attributes?.country}</Typography>
                    </div>
                  </Grid>
                  <CustomBox >
                    <Button style={webStyle.buttonStyle} data-test-id="viewDetailPage"
                      onClick={() => this.viewDetailPage(item.attributes?.id)}>View details</Button>
                  </CustomBox>
                </Grid>
              </CustomCard>
              </div>
            )
          })}</>
    )
  }
  headingStyle(name:string){ 
    return name === "You may be interested in" ? { ...webStyle.heading, marginTop: "64px" ,marginBottom : "0px" } : {...webStyle.heading ,marginBottom : "0px"};
  }

  renderStyle(name:string){
    const isFullView = this.state.nameOfPageWhichShowingFull === name;
    return isFullView || !this.state.nameOfPageWhichShowingFull ? "block" : "none"
  }

  renderData = (jobs: any, name: string) => {
    const {t_loader} = this.state
    const isFullView = this.state.nameOfPageWhichShowingFull === name;
    const job = isFullView ? jobs : jobs.slice(0, 5);
  
    if (t_loader) {
      return (
        <Box style={{
          width: "100%", display: "flex", height: "400px", justifyContent: "center",
          alignItems: "center"
        }}>
          <CircularProgress />
        </Box>
      );
    }
    if(t_loader === false && (job.length === 0 || !job)){
      return <>
        <Box style={webStyle.noResultBox}>
          <Box>
            <img src={noResult} alt="No results found" />
            <Typography style={webStyle.noResultText}>No results found</Typography>
            <Typography style={webStyle.subLine}>Try searching using another keyword</Typography>
          </Box>
        </Box>
      </> 
     
    }
    if (t_loader === false) {
      return this.renderTeacherListing(job)
    }
    
  };
  defineName(name:string){
    return !this.state.isFullView && <Typography style={webStyle.recently}>{name}</Typography>
    
  }

  renderHeading(dataTestId:string , handleView:(dataTestId:string)=>void){
   return  this.state.isFullView ? (
      this.headingBox()
    ) : (
      <div style={{ display: "flex" }}>
        <Typography style={webStyle.view} data-test-id={dataTestId} onClick={() => handleView(dataTestId)}>View all</Typography>
        <ArrowForwardIosIcon style={webStyle.forwardArrow} />
      </div>
    )
  }

  hasTokenAdmin = () => {
    const { substituteTeacherDetails } = this.state;
    const screen = this.state.nameOfPageWhichShowingFull || this.state.filteredItem;
  
    const renderSection = (jobs:any[], name:string, handleView:(dataTestId:string)=>void, dataTestId:string) => {
      const selectedItems = this.activeFilters();
      const headingStyle = this.headingStyle(name)
      const displayStyle = this.renderStyle(name)
  
  
      return (
        <div style={{ display: displayStyle }}>
          <div style={headingStyle}>
          {this.defineName(name)}
          {this.renderHeading(dataTestId , handleView)}
          </div>
          {this.showSelectedFilter(selectedItems)}
          {this.renderData(jobs, name)}
        </div>
      );
    };
  
    this.headingBox();
  
    if (screen === "Top Candidates") {
      return renderSection(substituteTeacherDetails, "Top Candidates", this.handleViewForTopCandidates, "topcandidates");
    } else if (!this.state.isFullView && !this.state.isSearch) {
      return (
        <>
          {renderSection(substituteTeacherDetails, "Top Candidates", this.handleViewForTopCandidates, "topcandidates")}
        </>
      );
    }
    
  };

  renderHasTokenScreen(job: any[] , loader:boolean) {
    if (loader) {
      return <>
        <Box style={{
          width: "100%", display: "flex", height: "400px", justifyContent: "center",
          alignItems: "center"
        }}>
          <CircularProgress />
        </Box>
      </> 
    }
  
    if (loader === false  && (job.length === 0 || !job)) {
      return (
        <Box style={webStyle.noResultBox}>
          <Box>
            <img src={noResult} alt="No results found" />
            <Typography style={webStyle.noResultText}>No results found</Typography>
            <Typography style={webStyle.subLine}>
              Try searching using another keyword
            </Typography>
          </Box>
        </Box>
      );
    }
    if (loader ===false) {
      return this.renderApiData(job);
    }
  }
  
  handleSearchButton = async (event:any)=>{
    if (event.target.value == "") {
      await this.clearEnteredText()
    }
    this.handleTextFieldChange(event)
  }
  
  hasToken = () => {
    const {logInMatchingProfileJobs, loginRecentJobs, loginInterestedJobs, searchedResult } = this.state;
    const screen = this.state.nameOfPageWhichShowingFull || this.state.filteredItem;
  
    const getPaginationData = (dataTestId:string) => {
      switch (dataTestId) {
        case 'matchingProfile':
          return this.state.matchingJobsPagination;
        case 'recentlyadded':
          return this.state.recentJobsPagination;
        case 'youmayintrestedin':
          return this.state.interestedJobsPagination;
        default:
          return { current_page: 0, 
            next_page: null, 
            prev_page: null, 
            total_pages: 0, 
            total_count: 0 
          };
      }
    };
  
    const renderSection = (jobs:any[], name:string, handleView:(dataTestId:string , paginateData:Pagination|null)=>void, dataTestId:string,loader:boolean) => {
      const paginationData = getPaginationData(dataTestId);
      const selectedItems = this.activeFilters();
      const headingStyle = this.headingStyle(name)
      const job = this.state.isFullView ? jobs : jobs.slice(0, 5)
      const displayStyle = this.renderStyle(name)

  
      return (
        <div style={{ display: displayStyle }}>
          <div style={headingStyle}>
            {this.defineName(name)}
            {this.state.isFullView ? (
              this.headingBox()
            ) : 
            (
              <div style={{ display: "flex" }}>
                <Typography style={webStyle.view} data-test-id={dataTestId} onClick={() => handleView(dataTestId, paginationData)}>
                  View all
                  </Typography>
                <ArrowForwardIosIcon 
                style={webStyle.forwardArrow}
                />
              </div>
            )}
          </div>
          {this.showSelectedFilter(
            selectedItems
          )}
          {
          this.renderHasTokenScreen(job , loader)
          }
        </div>
      );
    };
  
    this.headingBox();
    const  {m_loader , r_loader ,y_loader}= this.state
    if (!this.state.isFullView && !this.state.isSearch) {
      return (
        <>
          {renderSection(logInMatchingProfileJobs, 
            "Matching your profile", 
            this.handleViewForMatchingProfileJobs, 
            "matchingProfile", m_loader)}
          {renderSection(loginRecentJobs, 
            "Recently added", 
            this.handleViewForRecentlyAddedJobs, 
            "recentlyadded" ,r_loader)}
          {renderSection(loginInterestedJobs, 
            "You may be interested in", 
            this.handleViewInterestedJobs, 
            "youmayintrestedin" ,y_loader)}
        </>
      );
    }
    switch (screen) {
      case "Matching your profile":
        return renderSection(logInMatchingProfileJobs, 
          "Matching your profile", 
          this.handleViewForMatchingProfileJobs, 
          "matchingProfile" ,m_loader);
      case "Recently added":
        return renderSection(loginRecentJobs, 
          "Recently added", 
          this.handleViewForRecentlyAddedJobs, 
          "recentlyadded" , r_loader);
      case "You may be interested in":
        return renderSection(loginInterestedJobs, 
          "You may be interested in", 
          this.handleViewInterestedJobs, 
          "youmayintrestedin" ,y_loader);
      default:
        if (this.state.isSearch && !this.state.filteredItem) {
          return renderSection(searchedResult, 
            this.state.enteredText || this.state.filteredItem, 
            this.handleViewForMatchingProfileJobs, "" , this.state.m_loader|| this.state.s_loader);
        }
        return null;
    }
  };
  

  renderTeacherListing = (data:any[]) => {
    const dataToRender = data;
    
    return (
      <>
        {dataToRender?.length > 0 &&
          dataToRender.map((item:any) => {
            
            return (
              <div style={{position:'relative'}}>
              <CustomCard>
                  <div style={webStyle.ContainerStyle}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "start" }}>
                      <Box sx={webStyle.imageProfile}>
                        <img src={item.attributes?.profile_image?.url || profilePic} 
                        data-test-id="profileImageTestiD"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = notFound;
                        }} alt={item.attributes.profile_image.url} style={{ aspectRatio: "1", width: '100%', height: 'auto', borderRadius: "50%" }} />
                      </Box>
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                      <Typography style={webStyle.teacherTitle}>{item.attributes?.first_name} {item.attributes?.last_name}</Typography>
                      <Typography style={{ ...webStyle.seconddiv, color: "#64748B", marginTop: "10px" , fontSize:"16px" }} >{item.attributes?.recent_work_experience?.position} Substitute Teacher</Typography>
                      <div style={{ marginTop: "17px" ,display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" , flexWrap:"wrap" , gap:"15px" }}>
                        <div style={{ display: "flex" ,justifyContent: "right",width: "100%"}}>
                          <div style={{display:"flex" , justifyContent:"center" , alignItems:"center"}}>
                            <WorkOutlineIcon style={{color:"#64748B",fontSize:"20px" , marginRight:"9px"}}/>
                            <Typography style={{ ...webStyle.smallText, marginRight: "25px" }}>{item.attributes?.total_experience}</Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                <ViewBox>
                  <Box >
                    <div style={{ display: "flex", gap: "10px", alignItems:"center" }}>
                      <div style={{ width: "20.67px", height: "16.67px" }}>
                        <img src={location} style={{ width: "100%" }} />
                      </div>
                      <Typography style={{...webStyle.anotherdiv}}>{item.attributes?.city}, {item.attributes?.state}, {item.attributes?.country}</Typography>
                    </div>
                  </Box>
                  <ViewButton >
                    <Button style={webStyle.buttonStyle} data-test-id="viewDetailPageNew" onClick={()=>this.viewDetailPageTeacher(item?.attributes?.id , item.attributes.email ,`${item.attributes.first_name} ${item.attributes.last_name}`)}>View details</Button>
                  </ViewButton>
                </ViewBox>
              </CustomCard>
              </div>
            )
          })
        }
      </>
    )
  }

  handleEnter = (event:React.KeyboardEvent<HTMLInputElement>):void=>{
    if(event.key == "Enter"){
      this.handleSearchButtonOnClick()
    }
  }

  listingTitle = ()=>{
    return this.state.fromViewTeacherDetail ?? 'Job listings';
  } 
  getMargin = () =>{
    return this.state.isFullView || this.state.isSearch ? "0px" :"25px"
  }
  
  getPlaceHolder = ()=>{
    return this.state.role == 1? "ex. Richard George"   :"ex. Assistant Teacher"
  }

  getDate(){
    return this.state.calendarValueDocStart || new Date()
  }

  getSelecetedDate(date:Date , state:Date|null){
    return date.getDate() === state?.getDate() &&
                            date?.getMonth() === state?.getMonth() &&
                            date?.getFullYear() === state?.getFullYear();
  }

  getBgColour(selectedDate:boolean){
    return selectedDate ? '#395169' : undefined
  }

  getColour(selectedDate:boolean){
    return selectedDate ? 'white' :undefined
  }

  getScreen(){
    const {role} = this.state
    return this.state.isToken && <>
      {role == 1  && this.hasTokenAdmin() }
      {role==2 && this.hasToken()}
        </>
  }
  render() {
    const states = [...state.USA];
    const allState = states.sort();
    const placeHolder = this.getPlaceHolder()
    const mx = this.getMargin() 
    const finalStates = this.state.showAll ? allState :allState.slice(0 , 4)
    const dateParts =
      this.state.selectedDateFrom !== ""
        ? this.state.selectedDateFrom.split("/")
        : moment
          .parseZone(new Date())
          .format("MM/DD/YYYY")
          .split("/");
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[0]) - 1;
    const days = parseInt(dateParts[1]);
    const date = new Date(year, month, days);
    const {role} = this.state
    return (
      <>
        <CustomNavBar>
          <Navbar
            navigation={this.props.navigation}
            id={""}
            NaviagationPage={"Home"}
          />
        </CustomNavBar>
        <CustomProfileHead> 
          <img src={logo} />
          <MenuIcon
          style={{ color: '#fff', position: 'absolute', right: '47px',cursor:'pointer' }}
          data-test-id="toggleDrawer"
          onClick={() => this.toggleDrawer(true)}
        />
        </CustomProfileHead>
        <DrawerComponent
          isOpen={this.state.isDrawerOpen}
          toggleDrawer={this.toggleDrawer} navigation={this.props.navigation}        />
        <MainBox>
          <NavigationCustom>
          {this.renderNavigation()}
          </NavigationCustom>
          <Grid container spacing={3} style={{marginTop:"30px"}}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <SearchCustomBox>
                <SearchTypography style={webStyle.searchStyle}>
                  Search
                </SearchTypography>
                <div style={{ display: "flex" }}>
                  <SearchTextField
                    variant="outlined"
                    placeholder={placeHolder}
                    onKeyPress={(event:React.KeyboardEvent<HTMLInputElement>) => {this.handleEnter(event)}}
                    data-test-id="handleTextFieldChange"
                    onChange={async(e) => {
                      await this.handleSearchButton(e)
                    }}
                    value={this.state.enteredText}
                    InputProps={{
                      endAdornment: this.state.enteredText && (
                        <IconButton
                          data-test-id="clearSearchText"
                          onClick={async()=>{
                              await this.clearEnteredText()
                            this.clearSearchText()}
                          }
                          edge="end"
                          aria-label="clear search text"
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  />
                  <div
                    style={webStyle.searchBox}
                    data-test-id="handlesearch"
                    onClick={this.handleSearchButtonOnClick}
                  >
                    <SearchIcon />
                  </div>
                </div>
              </SearchCustomBox>
              {this.state.showFilterBox && (
                <div style={{ ...webStyle.gridStyle, marginTop: "16px" }}>
                  <Typography style={webStyle.filterStyle}>Filters</Typography>
                  <div style={webStyle.filterBoxes}>
                    <Typography style={webStyle.filterType}>
                      Education Level
                    </Typography>
                    <div style={{ display: "flex" }}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked={this.state.bachelorCheck}
                          style={{ borderRadius: "10px" }}
                          data-test-id="handleBachelor"
                          onChange={this.handleBachelor}
                        />
                      </ThemeProvider>
                      <Typography style={webStyle.filters}>
                        Bachelor's degree
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked={this.state.associateCheck}
                          style={{ borderRadius: "10px" }}
                          data-test-id="handleAssociat"
                          onChange={this.handleAssociat}
                        />
                      </ThemeProvider>
                      <Typography style={webStyle.filters}>
                        Associate's degree
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked={this.state.highSchoolCheck}
                          style={{ borderRadius: "10px" }}
                          data-test-id="handlehighScool"
                          onChange={this.handlehighScool}
                        />
                      </ThemeProvider>
                      <Typography style={webStyle.filters}>
                        High school diploma or equivalent
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked={this.state.certificateCheck}
                          style={{ borderRadius: "10px" }}
                          data-test-id="handlecertificate"
                          onChange={this.handlecertificate}
                        />
                      </ThemeProvider>
                      <Typography style={webStyle.filters}>
                        Certification in substitute teaching
                      </Typography>
                    </div>
                  </div>
                  <div style={webStyle.filterBoxes}>
                    <Typography style={webStyle.filterType}>
                      Experience
                    </Typography>
                    <SliderComponent
                      value={this.state.value}
                      min={0}
                      max={20}
                      step={1}
                      onChange={this.handleChange}
                      data-test-id="handleChange"
                      valueLabelDisplay="auto"
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography style={webStyle.yearexp}>0 Yrs</Typography>
                      <Typography style={webStyle.yearexp}>20 Yrs</Typography>
                    </div>
                  </div>
                  <div style={{...webStyle.filterBoxes ,  maxHeight:"265px"}}>
                    <Typography style={webStyle.filterType}>State</Typography>
                    <div style={{maxHeight:'228px' , overflowY:"scroll"}}>
                    {finalStates.map((state, index) => (
                      <div key={index} style={{ display: "flex" }}>
                        <ThemeProvider theme={theme}>
                          <Checkbox
                            checked={this.state.selectedState === state ?true : false}
                            style={{ borderRadius: "10px" }}
                            data-test-id="handleStateSelect"
                            onChange={() => this.handleStateSelect(state)}
                          />
                        </ThemeProvider>
                        <Typography style={webStyle.filters}>
                          {state}
                        </Typography>
                      </div>
                    ))}
                    <Typography data-test-id ="handleToggle"  style={webStyle.viewMore} onClick={()=>this.handleToggle()} >
                      {this.state.showAll ? 'View Less' : 'View More'}
                      </Typography>
                     </div>
                  </div>
                  <div style={webStyle.filterBoxes}>
                    <Typography style={webStyle.filterType}>
                      Date Posted
                    </Typography>
                    <Box data-test-id='handleStartCalender' style={{ position: "relative" }}  onClick={(e) => this.handleStartCalender(e)}>
                        <DateStyle
                          fullWidth
                          placeholder="From"
                          value={this.state.textFieldValueDocStart}
                          disabledStyle={this.state.ageError}
                          data-test-id="user-date-testID"
                          disableUnderline
                          name="companyName"
                          readOnly
                        />
                        <CalendarImgBox>
                          <img
                            id="user-date"
                            src={calendar}
                            onClick={(e) => this.handleStartCalender(e)}
                            style={webStyle.calendarImg}
                          />
                        </CalendarImgBox>
                        </Box>
                    <CustomPopover
                      id="to-popover"
                      open={Boolean(this.state.anchorElDocStart)}
                      anchorEl={this.state.anchorElDocStart}
                      data-test-id="handleCloseDocanchorElDocStart"
                      onClose={this.handleCloseDocanchorElDocStart}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      <Calendar
                        value={this.state.calendarValueDocStart}
                        onClickDay={this.handleDateChangesdoc('calendarValueDocStart', 'textFieldValueDocStart', 'anchorElDocStart')}
                        data-test-id="handleDateChangesdoc"
                        view="month"
                        maxDetail="month"
                        maxDate={new Date()}
                        tileContent={({ date }) => {
                          const isSelectedDate = this.getSelecetedDate(date , this.state.calendarValueDocStart)
                          const style = {
                            borderRadius: '10px',
                            backgroundColor: this.getBgColour(isSelectedDate),
                            color: this.getColour(isSelectedDate),
                            top: "418px",
                            left: "100px",
                          };
                          return <div style={style} />;
                        }}
                      />
                            </CustomPopover>
                    <DateBox data-test-id='openDatePickerTo' style={{ position: "relative" }} onClick={(e) => this.handleendCalender(e)}>
                      <Box style={{ position: "relative" }}>
                        <DateStyle
                          fullWidth
                          placeholder="To"
                          value={this.convertFormatTo()}
                          disabledStyle={this.state.ageError}
                          data-test-id="user-date-testIDTo"
                          disableUnderline
                          name="companyName"
                          readOnly
                        />
                        <CalendarImgBox>
                          <img
                            id="user-date"
                            src={calendar}
                            onClick={(e) => this.handleendCalender(e)}
                            style={webStyle.calendarImg}
                          />
                        </CalendarImgBox>
                      </Box>
                      </DateBox>
                    <CustomPopover
                      id="to-popover"
                      open={Boolean(this.state.anchorElDocEnd)}
                      anchorEl={this.state.anchorElDocEnd}
                      onClose={this.handleCloseDocanchorElDocStart}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      <Calendar
                        value={this.state.calendarValueDocStart}
                        onClickDay={this.handleDateChangesdoc('calendarValueDocEnd', 'textFieldValueDocEnd', 'anchorElDocEnd')}
                        data-test-id="handleDateChangesdocTo"
                        view="month"
                        maxDetail="month"
                        maxDate={new Date()}
                        minDate={this.getDate()}
                        tileContent={({ date }) => {
                          const isSelectedDate =this.getSelecetedDate(date ,this.state.calendarValueDocEnd)
                          const style = {
                            borderRadius: '10px',
                            backgroundColor: this.getBgColour(isSelectedDate),
                            color: this.getColour(isSelectedDate),
                            top: "418px",
                            left: "100px",
                          };
                          return <div style={style} />;
                        }}
                      />
                    </CustomPopover>
                   
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} style={{position:'relative' , marginTop: mx}}>
              {!this.state.isToken &&
              this.hasNoToken()}
              {this.getScreen()}
              { !this.state.isToken && !this.state.isSearchedClicked &&  role === 1 && (<>
                <div style={webStyle.heading}>
                  <Typography style={webStyle.recently}>Top Candidates</Typography>
                  <div style={{ display: "flex" }}>
                    <Typography style={webStyle.view}>View all</Typography>
                    <ArrowForwardIosIcon style={webStyle.forwardArrow} />
                  </div>
                </div>
                {this.renderTeacherListing(this.state.substituteTeacherDetails)}
              </>)}
              {this.renderpagination()}
            </Grid>
          </Grid>
        </MainBox>
        <Footer
          NaviagationPage={"Home"}
          navigation={this.props.navigation}
          id={""}
        />
      </>
    );
  }
}

export default JobListing;

const theme = createTheme({
  props: {
    MuiCheckbox: {
      icon: <img src={unchecked} />,
      checkedIcon: <img src={checked} />,
    },
  },
});
const CalendarImgBox = styled(Box)({
  marginRight: "10px",
  cursor:"pointer",
  position: "absolute" as "absolute",
  top: 19,
  right: 0,
});
const CustomCard = styled(Box)({
  padding: "32px",
  background: "#FFFFFF",
  borderRadius: "16px",
  marginBottom: "42px",
  "@media only screen and (max-width: 664px)": {
    padding: "5px",
  },
})
const MainBox = styled(Box)({
  background: "#F8F8F8",
  position: "relative",
  padding: "40px 222px 120px 222px",
  "@media only screen and (max-width: 1330px)": {
    padding: 40,
  },
  "@media only screen and (max-width: 959px)": {
    paddingTop: 8,
    padding: 40,
  },
  "@media only screen and (max-width: 664px)": {
    padding: 37,
  },
  "@media only screen and (max-width: 414px)": {
    padding: 20,
  },
});
const ChipItem = styled(Chip)({
  backgroundColor: "#E7EFF2",
  color: "#475569",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  marginBottom: 24,
});
const ShowingHeading = styled(Typography)({
  color: "#475569",
  fontFamily: "Inter",
  fontSize: "24px",
  fontWeight: 400,
  lineHeight: "32px",
  "@media only screen and (max-width: 414px)": {
    fontSize: 14,
  },
});
const Dropdown = styled(Menu)({
  "& .MuiPopover-paper": {
    marginTop: "50px",
    border: '1px solid #E2E8F0',
    borderRadius: "8px"
  },
  "& .MuiPaper-elevation8": {
    boxShadow: "0px 4px 8px 0px #00000008",
    width: 180
  }
})
const role = localStorage.getItem("role");
const width = role === '1' ? 850 : 571; 

const CustomNavBar = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(width)]: {
    display: "none",
  },
}));
const CustomProfileHead = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down(width)]: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#395169",
    padding: 20,
    position: "relative",
    justifyContent: "center",
  },
}));
const ViewDetail = styled(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  "@media only screen and (max-width: 414px)": {
    display: "none",
  },
});
const CardTitle = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "26px",
  color: "0F172A",
  "@media only screen and (max-width: 414px)": {
    fontSize: 14,
  },
});
const SearchTextField = styled(TextField)({
  borderTopLeftRadius: "4px",
  borderBottomLeftRadius: "4px",
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderRadius: "unset",
  },
});
const CustomPopover = styled(Popover)({
  "& .MuiPaper-rounded": {
    padding: 10,
    borderRadius: '16px',
  },
  width: '100%',
  "& .react-calendar": {border: 'unset',},
  "& .react-calendar__tile--active:enabled": {
    borderRadius: '10px',
    background: '#395169',
  },
  "& .react-calendar__tile:enabled:hover": {borderRadius: '10px'},
  "& .react-calendar__year-view .react-calendar__tile": {
    padding: '1rem 0.5rem',},
  "& .react-calendar button": {
    borderRadius: '10px',
    border: '1px solid #E2E8F0',
  },
  "& .react-calendar__navigation button": {
    border: 'none'}

})
const SearchCustomBox = styled(Box)({
  padding: "24px",
  background: "#FFFFFF",
  borderRadius: "16px",
  marginTop: "51px",
  "@media only screen and (max-width: 571px)": {
    marginTop: -57,
    padding: 0,
    borderRadius: 8,
  },
  "@media only screen and (max-width: 414px)": {
    padding: 0,
    borderRadius: 8,
    marginTop: -37,
  },
});
const SearchTypography = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#1E293B",
  marginBottom: "24px",
  "@media only screen and (max-width: 571px)": {
    display: "none",
  },
});
const NavigationCustom = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: "none",
  },
});
const CustomBox = styled(Box)({
  display:"flex",
  justifyContent   :"space-between"
})
const ViewBox = styled(Box)({
   gap:"15px" , 
   borderTop: "1px solid #F1F5F9",
   paddingTop: "20px",
   marginTop: "35px",
   display: "flex",
   justifyContent: "space-between",
   "@media (max-width:375px )": {
    flexDirection :"column"
  },
})
const ViewButton = styled(Box)({
  display:"flex",
  justifyContent:"flex-end",
  "@media (max-width:375px )": {
    justifyContent:"flex-start"
  },
})
const SliderComponent = styled(Slider)({
  "& .MuiSlider-root": {
    color: "pink",
  },
  "& .MuiSlider-thumb": {
    width: "20px",
    height: "20px",
    backgroundColor: "#395169",
    marginTop: -8,
    marginLeft: -10,
  },
  "& .MuiSlider-rail": {
    height: "4px",
    backgroundColor: "#E4E4E4",
  },
  "& .MuiSlider-track": {
    height: "4px",
    backgroundColor: "#395169",
  },
  "& .MuiSlider-valueLabel": {
    color: "#395169",
    marginLeft: 9,
  },
});

const DateStyle = styled(Input)(({ disabledStyle }: any) => ({
  width: "100%",
  border: disabledStyle
    ? "1px solid #F87171"
    : "1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
  borderRadius: "8px",
  cursor:"pointer",
  height: "44px",
  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
  },
  padding: "10px 8px",
  marginTop: 10,
  '& .MuiInputBase-input':{
    cursor:"pointer"
  }
}));
const DateBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width:"100%",
  "@media (max-width:660px )": {
    minWidth: "unset",
    maxWidth: "unset",
    flexWrap: "wrap",
    flexDirection: "column",
  },
});

const PostedAtTypo = styled(Box)({
  display: "flex",
  gap: "5px",
  "@media only screen and (max-width: 375px)": {
    display: "none",
  },
});

const webStyle = {
  dot: {
    display: "inline-block",
    width: "4px",
    height: "4px",
    backgroundColor: "#64748B",
    borderRadius: "50%",
  },
  cards: {
    padding: "32px",
    background: "#FFFFFF",
    borderRadius: "16px",
    marginBottom: "42px",
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4,
  },
  calendarImg: {
    width: "24px",
    height: "24px",
    cursor:"pointer"
  },
  searchBtn: {
    height: "100%",
    width: "44px",
  },
  firstdiv: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "26px",
    color: "0F172A",
  },
  searchStyle: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1E293B",
    marginBottom: "24px",
  },
  seconddiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
  },
  smallText: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18px",
  },
  teacherTitle:{
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing :"-0.5%"
  },
  anotherdiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A",
  },
  gridStyle: {
    padding: "24px",
    background: "#FFFFFF",
    borderRadius: "16px",
    marginTop: "51px",
  },
  thirddiv: {
    backgroundColor: "#F8F8F8",
    padding: "2px 12px 2px 12px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000000",
    borderRadius: "2px",
  },
  fourthdiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A",
  },
  typeStyle: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#4788B7",
  },
  divStyle: {
    background: "#F8F8F8",
    padding: "140px 222px 120px 222px",
  },
  customdivStyle: {
    display: "flex",
    gap: "10px",
    marginTop: "12px",
    borderRadius: "2px",
  },
  buttonStyle: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#395169",
    border: "1px solid #395169",
    padding: "6px 16px 6px 16px",
    borderRadius: "8px",
    textTransform: "none" as "none",
  },
  ContainerStyle: {
    display: "flex",
    gap: "10px",
    marginTop: "20px",
    width:"100%"
  },
  newdiv: {
    borderTop: "1px solid #F1F5F9",
    paddingTop: "20px",
    marginTop: "35px",
    display: "flex",
    justifyContent: "space-between",
  },
  recently: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  view: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    cursor: "pointer"
  },
  heading: {
    display: "flex",
    marginBottom: "24px",
    justifyContent: "space-between",
    width: "100%",
    position:"relative" as any, bottom:"15px"
  },
  searchBox: {
    background: "#395169",
    padding: "10px",
    borderRadius: "0px 8px 8px 0px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    cursor:"pointer"
  },
  navigation: {
    display: "flex",
    gap: "10px",
    position: "absolute" as "absolute",
    top: 32,
    left: 127,
  },
  filterType: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    marginBottom: "16px",
  },
  filterStyle: {
    fontWeight: 600,
    color: "#1E293B",
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "24px",
    marginBottom: "24px",
    paddingBottom: "24px",
    borderBottom: "1px solid #F1F5F9",
  },
  filters: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginTop: 7,
  },
  filterBoxes: {
    marginBottom: "24px",
    paddingBottom: "24px",
    borderBottom: "1px solid #F1F5F9",
  },
  viewMore: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    marginTop: "16px",
    paddingLeft: 37,
    cursor : "pointer"
  },
  yearexp: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  showingHeading: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
  },
  clearall: {
    marginRight: "8px",
    marginTop: -16,
    color: "#64748B",
    backgroundColor: "transparent",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  noResultBox: {
    background: "#fff",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center" as "center",
    alignItem: "center" as "center",
    paddingTop: 88,
    paddingBottom: 128,
  },
  noResultText: {
    color: "0F172A",
    fontWeight: 600,
    marginTop: "16px",
    fontFamily: "Inter",
    fontSize: "20px",
    lineHeight: "28px",
  },
  subLine: {
    color: "475569",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Inter",
    marginTop: "8px",
    maxWidth: "313px",
  },
  zerotext: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  menuList: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  inviteBtn:{
    color: '#395169',
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none" as "none",
    padding: "10px 16px",
    borderRadius: 8,
    cursor: 'pointer',
    marginBottom:'-41px',
    position:'absolute' as 'absolute',
    right: -6,
    bottom: 2,
  },
  appliedButton:{
    background : "#49D858",
    borderRadius : "2px",
    border:"none",
    outline:"none",
    width:"100%",
    maxWidth :"82px",
    height : "28px",
    color :"#FFFFFF"
  },
  imageProfile: {
    width:"127px",
    height:"127px",
    "@media (max-width:768px)": {
      width: "80px",
      height: "80px"
    } 
  }
};

// Customizable Area End
