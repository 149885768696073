import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
import React from "react";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}
interface Skill {
  id: number;
  name: string;
}
interface FileDetails {
  url: string | undefined;
  certificateName: string;
  file: File;
  uploadDate: string;
  id: number;
  attachment_type: string;
  certification_id: number;
  attachment_id: number;
  start_date:string|null;
  end_date:string|null;
  size:string;
}

interface ApiFileDetails {
  id: number;
  name: string;
  file_type: string;
  url: string;
  created_at: string;
}

interface ApiResponse {
  certifications: {
    id: number;
    name: string;
    files: ApiFileDetails[];
    attachment_type: string;
  }[];
  letters_of_interests: ApiFileDetails[];
  credentials: ApiFileDetails[];
  transcripts: ApiFileDetails[];
  note_pages: ApiFileDetails[];
  letter_of_reference_emails: ApiFileDetails[];
}
export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data?: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}
interface EducationRecord {
  id:number|null;
  schoolName: string;
  course: string;
  specialization: string;
  textFieldValueEduFrom: string;
  textFieldValueEduTo: string;
}

export interface OnClickHandlers {
  personalLink: () => void;
  resumeLink: () => void;
  certificateLink: () => void;
  preferLink: () => void;
  availabilityLink: () => void;
};

export interface LinkItem {
  label: string;
  handler: keyof OnClickHandlers;
};


interface WorkExperience  {
  id:number|null;
  organization: string;
  position: string;
  experience: string;
  jobResponsibility: string;
  textFieldValue:string;
  textFieldValueTo:string;
};
interface Time {
  hour: number;
  minute: number;
  period: 'AM' | 'PM';
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRoleId: number;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  sliderValue: number;
  openPersonalInfo: boolean;
  openSinfo:boolean;
  firstName: string;
  last_Name: string;
  phoneNum: string;
  profileEmail: string;
  street: string;
  profileCity: string;
  state: string;
  country: string;
  zipCode: string;
  editModeAcc: boolean,
  editModeAdd: boolean,
  openIntroduction: boolean,
  openResume: boolean;
  anchorEl: null | HTMLElement;
  introductionText: string;
  introductionValueBox: boolean;
  openEducation: boolean;
  schoolName: string;
  schoolNameId: number;
  schoolNameIdTwo: number;
  course: string;
  specialization: string;
  courseList: string[];
  eduSubmitted: boolean;
  anchorElEducation: null | HTMLElement;
  anchorElWorkExp: null | HTMLElement;
  addMoreEdu: boolean;
  schoolNameTwo: string;
  courseTwo: string;
  specializationTwo: string;
  courseTwoList: string[];
  secondEduSubmit: boolean;
  displayaddmore: boolean;
  selectedDate: Date | null;
  token: string;
  userId: string;
  firstPhoneNumber: string;
  isIntorEditClicked: boolean;
  eduIdOne: number;
  eduIdTwo: number;
  eduDataOnePresent: boolean;
  eduDataTwiPresent: boolean;
  editEduOneClicked: boolean;
  editEduTwoClicked: boolean;
  introError: string;
  eduOneError: string;
  eduTwoError: string;
  workOneError: string;
  prfilePic: string;
  openWorkexperience: boolean;
  organizationOne: string;
  workExpIdOne: number | null;
  workExpIdTwo: number | null;
  introId: number | null;
  positionOne: string;
  experienceOne: number | null;
  jobResponsibilityOne: string;
  wrokExpOneSubmitted: boolean;
  editWorkExpOpen: boolean;
  addMoreWork: boolean;
  organizationTwo: string;
  positionTwo: string;
  experienceTwo: number | null;
  jobResponsibilityTwo: string;
  wrokExpTwoSubmitted: boolean;
  editWorkExpTwoOpen: boolean;
  workExpTwoError: string;
  secondWorkSubmitted: boolean;
  openSkills: boolean;
  newSkill: string;
  showTextField: boolean;
  skills: { id: number; name: string }[];
  anchorElSkills: null | HTMLElement;
  skillsPresent: boolean;

  openTeaching: boolean;
  newInterest: string;
  showTextFieldInterest: boolean;
  teachinInterests: { id: number; name: string }[];
  anchorElInnterest: null | HTMLElement;
  interestPresent: boolean;

  openJobPrefer: boolean;
  modalityCheck: boolean;
  savedAddresses: {
    [key: string]: boolean;
    'Family Provider': boolean;
    'Group Provider': boolean;
    'Center Based Program': boolean;
    'School Age Program': boolean;
  };
  ageGroups: {
    [key: string]: boolean;
    'Infants': boolean;
    'Toddlers': boolean;
    'Preschool': boolean;
    'School Age': boolean;
  };
  workingDays: {
    [key: string]: boolean;
    'Monday': boolean;
    'Tuesday': boolean;
    'Wednesday': boolean;
    'Thursday': boolean;
    'Friday': boolean;
    'Saturday': boolean;
    'Sunday': boolean;
  };
  selectedTimeShift: string;
  selectedNatureJob: string;
  distance: string;
  displaySummary: boolean;
  saveSuccessPrefer: boolean;
  selectedModalities: string[];
  selectedModalityIndices: number[];
  selectedAgeGroups: string[];
  selectedWorkingDays: string[];
  selectedWorkingDayIndices: number[];
  selectedAgeGroupIndices: number[];
  selectedTimeShiftId: string;
  selectedNatureJobId: string;
  openAvailbility: boolean;
  editAvailbility: boolean;
  workingDaysAva: {
    [key: string]: boolean;
    'Monday': boolean;
    'Tuesday': boolean;
    'Wednesday': boolean;
    'Thursday': boolean;
    'Friday': boolean;
    'Saturday': boolean;
    'Sunday': boolean;
  };
  isDayClicked: boolean;
  workTimes: { [key: string]: { start: Time; end: Time } };
  startClicked: boolean;
  endClicked: boolean;
  activeDay: string | null;
  anchorEl2: HTMLElement | null;
  calendarValue: Date|null;
  textFieldValue: string;
  anchorEl2To: HTMLElement | null;
  calendarValueTo: Date|null;
  textFieldValueTo: string;
  skillError: string;
  addskillClicked: boolean;
  interestError: string;
  addInterestClicked: boolean;
  anchorElSecFrom: HTMLElement | null;
  calendarValueSecFrom: Date;
  textFieldValueSecFrom: string;
  anchorElSecTo: HTMLElement | null;
  calendarValueSecTo: Date;
  textFieldValueSecTo: string;
  anchorElEduTo: HTMLElement | null;
  calendarValueEduTo: Date|null;
  textFieldValueEduTo: string;
  anchorElEduFrom: HTMLElement | null;
  calendarValueEduFrom: Date |null;
  iseditLogo:boolean;
  textFieldValueEduFrom: string;
  anchorElSecEduTo: HTMLElement | null;
  calendarValueSecEduTo: Date;
  textFieldValueSecEduTo: string;
  anchorElSecEduFrom: HTMLElement | null;
  calendarValueSecEduFrom: Date;
  textFieldValueSecEduFrom: string;
  openCertificate: boolean;
  openEditShareWith: boolean;
  selectedShareWith: string;
  files: FileDetails[];
  currentCertificateName: string;
  currentFile: File | null;
  fileSubmitted: boolean;
  currentUploadDate: string;
  anchorElCertificate: HTMLElement | null;
  anchorElFileId: number | null;
  uploadSuccess: boolean;
  deleteSuccess: boolean;
  deleteWorkConfirm: boolean;
  deleteEduConfirm: boolean;
  isEditing: boolean;
  isReuploading: boolean;
  selectedCertificateId: number;
  selectedAttachmentId: number;
  currentFileUrl:string;
  isEditUpload:boolean;
  emptyProfile:string[];
  selectedFileType:string;
  anchorElDocStart: HTMLElement | null;
  calendarValueDocStart: Date | null;
  textFieldValueDocStart: string;
  anchorElDocEnd: HTMLElement | null;
  calendarValueDocEnd: Date | null;
  textFieldValueDocEnd: string;
  certificateNameError:string;
  stardateError:string;
  enddateError:string;
  activeWorkId:number|null;
  deleteConfirmOne:boolean;
  deleteConfirmTwo:boolean;
  deleteEduConfirmOne:boolean;
  deleteEduConfirmTwo:boolean;
  saved_jobs_count:number|null;
  applied_jobs_count:number|null;
  viewed_jobs_count:number|null;
  job_created_count: number|null,
  job_accepted_by_substitute_count: number|null;

  educationRecords: EducationRecord[];
  currentEducation: EducationRecord;
  editIndex: number | null; 

  selectedEducationIndex:number|null;
  workExpRecords:WorkExperience[];
  currentWorkExp:WorkExperience;
  editIndexWork:number|null;
  selectedWorkIndex:number|null;
  isDrawerOpen:boolean;
  SelectedSize:string,
  isIntroductionEmpty: boolean,
  isStreetEmpty: boolean,
  isCityEmpty: boolean,
  isStateEmpty: boolean,
  isZipCodeEmpty: boolean,
  isCountryEmpty: boolean,
  isTravelDistanceEmpty: boolean,
  isPreferredModalityEmpty: boolean,
  isPreferredAgeGroupEmpty: boolean,
  isPreferredWorkingDaysEmpty: boolean,
  isPreferredNatureOfJobsEmpty: boolean,
  isPreferredTimeShiftsEmpty: boolean,
  LogoId:any,
  saved_substitute_teachers_count:number;
  s_state:string;
  s_city:string;
  s_country:string;
  s_zip:string;
  s_street:string,
  loader:boolean,
  isMobileView: boolean,
  countryCode:string|null,
  phoneNumError:string,
  emailError:string,
  isValid:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getProfileDataId: string = "";
  currentYear: number = new Date().getFullYear();
  updateProfileAcc: string = '';
  updateProfileAdd: string = '';
  uploadIntroId: string = '';
  editItnroId: string = '';
  deleIntroId: string = '';
  getIntroId: string = "";
  uploadEduId: string = '';
  uploadEduTwoId: string = '';
  getEduId: string = '';
  deletEduOne: string = '';
  deletEduTwoId: string = '';
  getWorkExpId: string = "";
  uploadWorkExpId: string = "";
  editWorkOneId: string = '';
  uploadWorkExpTwoId: string = '';
  deleteWorkTwoId: string = '';
  deleteLogoId:string='';
  deleteWorkOneId: string = '';
  addSkillsId: string = '';
  getSkillsId: string = '';
  addIntId: string = "";
  getIntrestId: string = "";
  getJobPreferId: string = "";
  uploadJobPreferId: string = '';
  uploadWorkingId: string = '';
  setTimingId: string = '';
  getTimeId: string = '';
  getCertificates: string = "";
  editShareWithId: string = '';
  fileInputRef = React.createRef<HTMLInputElement>();
  deleteCertificateId: string = "";
  uploadeDocId: string = '';
  editCertificateid: string = '';
  profileInputRef= React.createRef<HTMLInputElement>();
  editProfilepic:string='';
  personalInfoRef = React.createRef<HTMLDivElement>();
  personalRef = React.createRef<HTMLDivElement>();
  resumeRef = React.createRef<HTMLDivElement>();
  certificateRef = React.createRef<HTMLDivElement>();
  jobPreferRef = React.createRef<HTMLDivElement>();
  availabilityRef = React.createRef<HTMLDivElement>();
  countDataId:string="";
  countDataAdminId:string="";
  putForSchoolId:string='';
  postForSchoolId:string='';
  getSchoolInformation: string = '';
  // emailReg:RegExp
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],
      schoolNameId: 0,
      schoolNameIdTwo: 0,
      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRoleId: 1,
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      sliderValue: 2,
      openPersonalInfo: false,
      openSinfo:false,
      firstName: '',
      last_Name: "",
      phoneNum: "",
      profileEmail: "",
      street: '',
      profileCity: "",
      state: "",
      country: "",
      zipCode: "",
      editModeAcc: false,
      editModeAdd: false,
      openResume: false,
      openIntroduction: false,
      anchorEl: null,
      introductionText: '',
      introductionValueBox: false,
      openEducation: false,
      schoolName: "",
      course: "Select option",
      specialization: '',
      courseList:["Bachelor's degree","Associate's degree","High school diploma or equivalent","Certification in substitute teaching"],
      eduSubmitted: false,
      anchorElEducation: null,
      anchorElWorkExp: null,
      addMoreEdu: false,
      schoolNameTwo: '',
      courseTwo: 'Select option',
      specializationTwo: '',
      courseTwoList: ["Master of Arts", 'MCA'],
      secondEduSubmit: false,
      displayaddmore: false,
      selectedDate: new Date(),
      token: '',
      userId: '',
      firstPhoneNumber: '',
      isIntorEditClicked: false,
      eduIdOne: 0,
      eduIdTwo: 0,
      eduDataOnePresent: false,
      eduDataTwiPresent: false,
      editEduOneClicked: false,
      editEduTwoClicked: false,
      introError: '',
      eduOneError: '',
      eduTwoError: '',
      workOneError: '',
      prfilePic: '',
      openWorkexperience: false,
      organizationOne: '',
      positionOne: '',
      experienceOne: null,
      jobResponsibilityOne: '',
      wrokExpOneSubmitted: false,
      editWorkExpOpen: false,
      addMoreWork: false,
      organizationTwo: '',
      positionTwo: '',
      experienceTwo: null,
      jobResponsibilityTwo: '',
      wrokExpTwoSubmitted: false,
      editWorkExpTwoOpen: false,
      workExpTwoError: '',
      secondWorkSubmitted: false,
      openSkills: false,
      newSkill: '',
      skills: [],
      showTextField: true,
      anchorElSkills: null,
      skillsPresent: false,
      openTeaching: false,
      newInterest: '',
      showTextFieldInterest: true,
      teachinInterests: [],
      anchorElInnterest: null,
      interestPresent: false,

      openJobPrefer: false,
      modalityCheck: false,
      savedAddresses: {
        'Family Provider': false,
        'Group Provider': false,
        'Center Based Program': false,
        'School Age Program': false,
      },
      ageGroups: {
        'Infants': false,
        'Toddlers': false,
        'Preschool': false,
        'School Age': false,
      },
      workingDays: {
        'Monday': true,
        'Tuesday': true,
        'Wednesday': true,
        'Thursday': true,
        'Friday': true,
        'Saturday': true,
        'Sunday': true,
      },
      selectedTimeShift: '',
      selectedNatureJob: '',
      distance: '',
      displaySummary: false,
      selectedModalities: [],
      selectedAgeGroups: [],
      selectedWorkingDays: [],
      selectedModalityIndices: [],
      selectedWorkingDayIndices: [],
      selectedAgeGroupIndices: [],
      selectedTimeShiftId: '',
      selectedNatureJobId: '',
      saveSuccessPrefer: false,
      openAvailbility: false,
      editAvailbility: false,
      workingDaysAva: {
        'Monday': true,
        'Tuesday': true,
        'Wednesday': true,
        'Thursday': true,
        'Friday': true,
        'Saturday': true,
        'Sunday': true,
      },
      isDayClicked: true,
      workTimes: {},
      startClicked: false,
      endClicked: false,
      activeDay: null,
      anchorEl2: null,
      calendarValue: new Date(),
      textFieldValue: '',
      anchorEl2To: null,
      calendarValueTo: new Date(),
      textFieldValueTo: '',
      skillError: '',
      addskillClicked: false,
      interestError: '',
      addInterestClicked: false,
      anchorElSecFrom: null,
      calendarValueSecFrom: new Date(),
      textFieldValueSecFrom: '',
      anchorElSecTo: null,
      calendarValueSecTo: new Date(),
      textFieldValueSecTo: '',
      anchorElEduTo: null,
      calendarValueEduTo: new Date(),
      textFieldValueEduTo: '',
      anchorElEduFrom: null,
      calendarValueEduFrom: new Date(),
      textFieldValueEduFrom: '',
      anchorElSecEduTo: null,
      calendarValueSecEduTo: new Date(),
      textFieldValueSecEduTo: '',
      anchorElSecEduFrom: null,
      calendarValueSecEduFrom: new Date(),
      textFieldValueSecEduFrom: '',
      openCertificate: false,
      openEditShareWith: false,
      selectedShareWith: '',
      files: [],
      currentCertificateName: '',
      currentFile: null,
      fileSubmitted: false,
      currentUploadDate: '',
      anchorElCertificate: null,
      anchorElFileId: null,
      workExpIdOne: null,
      workExpIdTwo: null,
      introId: null,
      uploadSuccess: false,
      deleteSuccess: false,
      deleteWorkConfirm: false,
      deleteEduConfirm: false,
      isEditing: false,
      isReuploading: false,
      selectedCertificateId: 0,
      selectedAttachmentId: 0,
      currentFileUrl:'',
      isEditUpload:false,
      emptyProfile:[],
      selectedFileType:'',
      anchorElDocStart: null,
      calendarValueDocStart: null,
      textFieldValueDocStart: '',
      anchorElDocEnd: null,
      calendarValueDocEnd: null,
      textFieldValueDocEnd: '',
      certificateNameError:'',
      stardateError:'',
      enddateError:'',
      activeWorkId:null,
      deleteConfirmOne:false,
      deleteConfirmTwo:false,
      deleteEduConfirmOne:false,
      deleteEduConfirmTwo:false,
      saved_jobs_count:0,
      applied_jobs_count:0,
      viewed_jobs_count:0,
      job_created_count: 0,
      job_accepted_by_substitute_count: 0,
      saved_substitute_teachers_count:0,

      educationRecords: [],
      currentEducation: {
        id:null,
        schoolName: '',
        course: 'Select option',
        specialization: '',
        textFieldValueEduFrom: '',
        textFieldValueEduTo: '',
      },
      editIndex: null, 
      selectedEducationIndex:null,
      workExpRecords:[],
      currentWorkExp:{ id:null,
        organization: '',
        position: '',
        experience: '',
        jobResponsibility: '',
        textFieldValue:'',
        textFieldValueTo:''},
      editIndexWork:null,
      selectedWorkIndex:null,
      isDrawerOpen:false,
      SelectedSize:'',
      LogoId : '',
      isIntroductionEmpty: false,
      isStreetEmpty: false,
      isCityEmpty: false,
      isStateEmpty: false,
      isZipCodeEmpty: false,
      isCountryEmpty: false,
      isTravelDistanceEmpty: false,
      isPreferredModalityEmpty: false,
      isPreferredAgeGroupEmpty: false,
      isPreferredWorkingDaysEmpty: false,
      isPreferredNatureOfJobsEmpty: false,
      isPreferredTimeShiftsEmpty: false,
      iseditLogo:false,
      s_state: '',
      s_city: '',
      s_country: '',
      s_zip: '',
      s_street: '',
      loader:false,
      countryCode:"",
      isMobileView: window.innerWidth <= 571,
      phoneNumError:'',
      emailError:"",
      isValid: true
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.setState({
      loader:true
    })
    const loginId = await storage.get("loginId")
    const token = await storage.get("loginToken")
    this.setState({ profileRoleId: await storage.get("role") })
    this.setState({ token: token, userId: loginId })
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.state.profileRoleId == 2) {
      this.getProfileData()
      this.getIntro()
      this.getEducationDetails()
      this.getWorkExp()
      this.getSkills()
      this.getJobPreferences()
      this.getuploadedCertificates()
      this.showAllTimeData()
      this.setScaleValue()
      this.getIntrest()
      this.getCount()
    }
    else {
      this.getSchoolData(loginId)
      this.getAdminCount()
      this.getProfileData()
      this.setScaleValueAdmin()
    }
    const storedSkillIds = JSON.parse(await storage.get('skilsss') || '[]');
    this.setState({ skills: storedSkillIds })
    const storedIntrest = JSON.parse(await storage.get('intrestsss') || '[]');
    this.setState({ teachinInterests: storedIntrest })
    this.setScaleValue()
    this.setState({
      profileRoleId : await storage.get("role")
    }) 
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      this.handleApi(apiRequestCallId , responseJson)
      if (apiRequestCallId == this.getProfileDataId) {
        const apiData = responseJson.data.attributes
        this.updateStateWithProfileData(apiData);
        this.shareWithdoc(apiData)
      }
      if (apiRequestCallId === this.deletEduOne) {
        this.getEducationDetails()
      }
      if (apiRequestCallId == this.uploadIntroId) {
        storage.set("introId", responseJson?.data?.attributes?.id)
      }

      if(apiRequestCallId == this.countDataId){
        this.setState({
          saved_jobs_count : responseJson.data.saved_jobs_count,
          viewed_jobs_count :responseJson.data.viewed_jobs_count,
          applied_jobs_count : responseJson.data.applied_jobs_count,
        })
      }


      if(apiRequestCallId == this.countDataAdminId){
        this.setState({
          job_created_count : responseJson.job_created_count,
          job_accepted_by_substitute_count :responseJson.job_accepted_by_substitute_count,
          saved_substitute_teachers_count : responseJson.saved_substitute_teachers_count
        })
      }
      if (this.state.profileRoleId == 2) {
      this.desApi(apiRequestCallId, responseJson)
      this.apiId2(apiRequestCallId, responseJson)

      this.workExpApi(apiRequestCallId, responseJson)
      this.skillSApi(apiRequestCallId, responseJson)
      this.intrestApi(apiRequestCallId, responseJson)
      this.jobPreferApi(apiRequestCallId, responseJson)
      this.timeApi(apiRequestCallId, responseJson)
      this.certificateApi(apiRequestCallId, responseJson)
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleMobileView);
  }
  handleMobileView = () => {
    this.setState({ isMobileView: window.innerWidth <= 571 });
  };
  
  handleApi = async (apiRequestCallId:string , responseJson:any)=>{
    if (apiRequestCallId === this.putForSchoolId) {
      this.setSchooldata(responseJson)
      this.setScaleValueAdmin();
    }
    if (apiRequestCallId === this.postForSchoolId) {
      this.setSchooldata(responseJson)
      this.setScaleValueAdmin();
    }
    if(apiRequestCallId == this.getSchoolInformation){
      if (responseJson.errors) {
        this.setState({
          loader:false
        })
        this.handleSubmitFromAdmin()
      }
      else{
        this.setState({
          loader:false
        })
        this.setSchooldata(responseJson)
      this.setScaleValueAdmin();
      }
    }
    if (apiRequestCallId == this.deleteLogoId) {
      this.getSchoolData(await storage.get("loginId"))
      this.setScaleValueAdmin();
    } 
  }

  setSchooldata = (responseJson: any) => {
    const { attributes } = responseJson.data;
    const {
        id,
        logo: { url: logoUrl },
        introduction = '',
        street = '',
        city = '',
        state = '',
        country = '',
        zip_code: zipCode = '',
        travel_distance: distance = 0,
        preferred_modality_ids: preferredModalityIds = [],
        preferred_age_group_ids: preferredAgeGroupIds = [],
        preferred_working_days_ids: preferredWorkingDaysIds = [],
        preferred_time_shifts_ids: preferredTimeShiftsIds = [],
        preferred_nature_of_jobs_ids: preferredNatureOfJobsIds = [],
        preferred_modality_name: preferredModalityName = [],
        preferred_age_group_name: preferredAgeGroupName = [],
        preferred_working_days_name: preferredWorkingDaysName = [],
        preferred_time_shifts_name: preferredTimeShiftsName = [],
        preferred_nature_of_jobs_name: preferredNatureOfJobsName = [],
    } = attributes;

    const getFirstOrEmpty = (arr: any[]) => arr.length > 0 ? arr[0].toString() : '';

    storage.set("schoolid", id);

    this.setState({
        currentFileUrl: logoUrl,
        LogoId: id,
        introductionText: introduction,
        s_street : street,
        s_city: city,
        s_state:state,
        s_country:country,
        s_zip : zipCode,
        distance,
        selectedModalityIndices: preferredModalityIds,
        selectedAgeGroupIndices: preferredAgeGroupIds,
        selectedWorkingDayIndices: preferredWorkingDaysIds,
        selectedTimeShiftId: getFirstOrEmpty(preferredTimeShiftsIds),
        selectedNatureJobId: getFirstOrEmpty(preferredNatureOfJobsIds),
        selectedModalities: preferredModalityName,
        selectedAgeGroups: preferredAgeGroupName,
        selectedWorkingDays: preferredWorkingDaysName,
        selectedTimeShift: getFirstOrEmpty(preferredTimeShiftsName),
        selectedNatureJob: getFirstOrEmpty(preferredNatureOfJobsName),
    });
};

updateStateWithProfileData = (apiData:any) => {
  let number =''
  if (apiData?.full_phone_number?.length == 11) {
    number = apiData?.full_phone_number?.slice(1,11)    
  }
  else if(apiData?.full_phone_number?.length == 12){
    number = apiData?.full_phone_number?.slice(2,12)
  }
  else{
    number = apiData?.full_phone_number
  }
  const commonState:any = {
      firstName: apiData.first_name,
      last_Name: apiData.last_name,
      phoneNum: apiData?.phone_number || number,
      profileEmail: apiData.email,
  };

  const additionalState = {
          street: apiData.street_address,
          profileCity: apiData.city,
          state: apiData.state,
          country: apiData.country,
          zipCode: apiData.zip_code,
          prfilePic: apiData.profile_image.url,
          countryCode : apiData?.country_code
        }

  this.setState({
      ...commonState,
      ...additionalState,
        loader:false,
        selectedWorkingDayIndices : apiData.preferred_working_days_ids
  });
};

  scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  shareWithdoc = (apiData: { document_sharings_ids: number[]; }) => {
    if (apiData.document_sharings_ids[0] == 1) {
      this.setState({ selectedShareWith: 'Share with Palmetto' })
    }
    if (apiData.document_sharings_ids[0] == 2) { this.setState({ selectedShareWith: 'Share with Job Providers' }) }
    if (apiData.document_sharings_ids[0] == 3) { this.setState({ selectedShareWith: 'Share with both (Palmetto and Job Providers)' }) }
  }
  desApi = (apiRequestCallId: string, responseJson: {
    data: {
      attributes: {
        id: number | null; description: string;
      };
    };
  }) => {
    if (apiRequestCallId == this.getIntroId) {
      this.setState({ introductionText: responseJson.data.attributes.description, introId: responseJson.data.attributes.id })
      if (responseJson.data.attributes.description !== '') {
        this.setState({ openIntroduction: false, introductionValueBox: true })
      }
    }
  }
  apiId2 = async (apiRequestCallId: string, responseJson: { data: any; }) => {
    if (apiRequestCallId == this.uploadEduId) {
      if(responseJson){
        this.getEducationDetails()
      }
    }
    if (apiRequestCallId == this.uploadEduTwoId) {
      this.setState({ eduIdTwo: responseJson.data.attributes.id })
      this.setState({
        schoolNameTwo: responseJson.data.attributes.university, courseTwo: responseJson.data.attributes.course,
        specializationTwo: responseJson.data.attributes.specialization,
        schoolNameIdTwo: responseJson.data.attributes.id
      })
    }
    if (apiRequestCallId == this.getEduId) {
      const { data } = responseJson;

      if (data && data.length > 0) {
        this.setState({
          educationRecords: data.map((record: { attributes: { id: number; university: string; course: string; specialization: string; from_date: Date; to_date: Date; }; }) => ({
            id: record.attributes.id,
            schoolName: record.attributes.university,
            course: record.attributes.course,
            specialization: record.attributes.specialization,
            textFieldValueEduFrom: record.attributes.from_date,
            textFieldValueEduTo: record.attributes.to_date,
          })),
          eduSubmitted: true,
        });
      }
      if(data.length==0){
        this.setState({
           educationRecords:[],
           eduSubmitted: false,
           openEducation:false
        })
      }
    }
  }
  formatDateToMonthYear = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString('en-US', options);
  };


  workExpApi = async (apiRequestCallId: string, responseJson: { data: any; }) => {
    if (apiRequestCallId == this.uploadWorkExpId) {
      this.getWorkExp()
    }
    if (apiRequestCallId == this.getWorkExpId) {
      const { data } = responseJson;

      if (data.length > 0) {

        this.setState({
          workExpRecords:data.map((record: { attributes: { id: number; organization: string; position: string; experience: string; job_responsibilities: string; start_date: string; end_date: string; }; })=>({
            id:record.attributes.id,
            organization:record.attributes.organization,
            position:record.attributes.position,
            experience:record.attributes.experience,
            jobResponsibility: record.attributes.job_responsibilities,
            textFieldValue:this.formatDateToMonthYear(record.attributes.start_date),
            textFieldValueTo:this.formatDateToMonthYear(record.attributes.end_date)
          })),
          wrokExpOneSubmitted:true
        });
      }
      if(data.length==0){
        this.setState({workExpRecords:[],wrokExpOneSubmitted:false,openWorkexperience:false})
      }
    }
    if (apiRequestCallId == this.uploadWorkExpTwoId) {
      this.setState({
        organizationTwo: responseJson.data.attributes.organization,
        positionTwo: responseJson.data.attributes.position,
        jobResponsibilityTwo: responseJson.data.attributes.job_responsibilities,
        experienceTwo: responseJson.data.attributes.experience
      })
    }
    if (apiRequestCallId==this.deleteWorkOneId) {
      storage.remove('workIdOne')
      this.getWorkExp()
    }
    if (apiRequestCallId == this.deleteWorkTwoId) {
      storage.remove('workIdTwo')
    }
  }

  skillSApi = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId == this.addSkillsId) {
      const storedSkillIds = JSON.parse(await storage.get("skillIds") || "[]");

      const newSkillId = responseJson.data.attributes.id;
      storedSkillIds.push(newSkillId);
      storage.set("skillIds", JSON.stringify(storedSkillIds));

      const newSkillWithId = { id: responseJson.data.attributes.id, name: responseJson.data.attributes.name };
      this.setState((prevState) => ({
        skills: [...prevState.skills, newSkillWithId],
        newSkill: '',
      }));
    }
    if (apiRequestCallId == this.getSkillsId) {
      const storedSkillIds = JSON.parse(await storage.get('skillIds') || '[]');
      const skills = responseJson.data
        .map((skill: any) => ({
          id: skill.attributes.id,
          name: skill.attributes.name,
        }))
        .filter((skill: Skill) => storedSkillIds.includes(skill.id));
      this.setState({ skills });
      if (this.state.skills.length > 0) {
        this.setState({ skillsPresent: true, openSkills: true, showTextField: false })
      }
      storage.set("skilsss", JSON.stringify(this.state.skills))
    }
  }

  intrestApi = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId == this.addIntId) {
      const storedIntIds = JSON.parse(await storage.get("intrestIds") || "[]");

      const newSkillId = responseJson.data.attributes.id;
      storedIntIds.push(newSkillId);
      storage.set("intrestIds", JSON.stringify(storedIntIds));

      const newSkillWithId = { id: responseJson.data.attributes.id, name: responseJson.data.attributes.name };
      this.setState((prevState) => ({
        teachinInterests: [...prevState.teachinInterests, newSkillWithId],
        newInterest: '',
      }));
    }
    if (apiRequestCallId == this.getIntrestId) {
      const storedIntIds = JSON.parse(await storage.get('intrestIds') || '[]');
      const teachinInterests = responseJson.data
        .map((skill: any) => ({
          id: skill.attributes.id,
          name: skill.attributes.name,
        }))
        .filter((skill: Skill) => storedIntIds.includes(skill.id));
      this.setState({ teachinInterests });
      if (this.state.teachinInterests.length > 0) {
        this.setState({ interestPresent: true, openTeaching: true, showTextFieldInterest: false })
      }
      storage.set("intrestsss", JSON.stringify(this.state.teachinInterests))
    }
  }

  jobPreferApi = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId == this.getJobPreferId) {
      this.setState({ displaySummary: true })
      const {
        preferred_travel_distance,
        preferred_modality_name,
        preferred_age_group_name,
        preferred_working_days_name,
        preferred_nature_of_jobs_name,
        preferred_time_shifts_name
      } = responseJson.data?.attributes;


      this.setState({
        selectedModalities: preferred_modality_name,
        selectedAgeGroups: preferred_age_group_name,
        selectedWorkingDays: preferred_working_days_name,
        selectedTimeShift: preferred_time_shifts_name[0] || '',
        selectedNatureJob: preferred_nature_of_jobs_name[0] || '',
        distance: preferred_travel_distance.toString(),
      });
    }
  }
  timeApi = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getTimeId) {
      const workingDays: any = {
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
        Sunday: true,
      };
      const workTimes: { [key: string]: any } = {};

      responseJson.data.forEach((item: any) => {
        const dayName = item.attributes.working_day_name;
        const isFullDay = item.attributes.full_day;
        workingDays[dayName] = isFullDay;

        if (!isFullDay) {
          workTimes[dayName] = {
            start: this.parseTime(item.attributes.start_time),
            end: this.parseTime(item.attributes.end_time)
          };
        }
      });

      this.setState({
        workingDays,
        workTimes
      });
    }
  };

  certificateApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId == this.getCertificates) {

      const mapFiles = (
        files: any[], 
        certificateName: string, 
        certificationId: number, 
        attachmentType: string, 
        attachment_id: number,
        start_date: string, 
        end_date: string,
        size:string,
      ) => {
        return files.map((file: any) => ({
          certificateName: certificateName,
          file: new File([], file.name, { type: file.file_type }),
          uploadDate: file.created_at,
          id: file.id,
          attachment_type: attachmentType,
          certification_id: certificationId,
          attachment_id: attachment_id,
          url: file.url,
          start_date: start_date,
          end_date: end_date,
          size: file.size 
        }));
      };
      
      const certifications = responseJson.certifications.flatMap((certification: any) =>
        mapFiles(
          certification.files, 
          certification.name, 
          certification.id, 
          'certifications', 
          certification.files.map((file: any) => file.id),
          certification.start_date, 
          certification.end_date  ,
          certification.files.size
        )
      );
      
      const lettersOfInterest = responseJson.letters_of_interests.map((file: any) =>
      ({
        certificateName: file.attachment_type,
        file: new File([], file.name, { type: file.file_type }),
        uploadDate: file.created_at,
        id: file.id,
        attachment_type: 'letters_of_interests',
        certification_id: 0,
        attachment_id: file.id,
        url:  file.url,
        start_date:file.start_date,
        end_date:file.end_date,
        size:file.size
      })
      );

      const credentials = responseJson.credentials.map((file: any) =>
      ({
        certificateName:file.attachment_type,
        file: new File([], file.name, { type: file.file_type }),
        uploadDate: file.created_at,
        id: file.id,
        attachment_type: 'credentials',
        certification_id: 0,
        attachment_id: file.id,
        url: file.url,
        start_date:file.start_date,
        end_date:file.end_date,
        size:file.size
      })
      );

      const transcripts = responseJson.transcripts.map((file: any) =>
      ({
        certificateName: file.attachment_type,
        file: new File([], file.name, { type: file.file_type }),
        uploadDate: file.created_at,
        id: file.id,
        attachment_type: 'transcripts',
        certification_id: 0,
        attachment_id: file.id,
        url: file.url,
        start_date:file.start_date,
        end_date:file.end_date,
        size:file.size
      })
      );

      const notePages = responseJson.note_pages.map((file: any) =>
      ({
        certificateName: file.attachment_type,
        file: new File([], file.name, { type: file.file_type }),
        uploadDate: file.created_at,
        id: file.id,
        attachment_type: 'note_pages',
        certification_id: 0,
        attachment_id: file.id,
        url: file.url,
        start_date:file.start_date,
        end_date:file.end_date,
        size:file.size
      })
      );

      const letterOfReferenceEmails = responseJson.letter_of_reference_emails.map((file: any) =>
      ({
        certificateName: file.attachment_type,
        file: new File([], file.name, { type: file.file_type }),
        uploadDate: file.created_at,
        id: file.id,
        attachment_type: 'letter_of_reference_emails',
        certification_id: 0,
        attachment_id: file.id,
        url: file.url,
        start_date:file.start_date,
        end_date:file.end_date,
        size:file.size
      })
      );
      const uploadResumes = responseJson.upload_resumes.map((file: any) =>
      ({
        certificateName: file.attachment_type,
        file: new File([], file.name, { type: file.file_type }),
        uploadDate: file.created_at,
        id: file.id,
        attachment_type: 'upload_resumes',
        certification_id: 0,
        attachment_id: file.id,
        url: file.url,
        start_date:file.start_date,
        end_date:file.end_date,
        size:file.size
      })
      );

      const files = [
        ...uploadResumes,
        ...certifications,
        ...lettersOfInterest,
        ...credentials,
        ...transcripts,
        ...notePages,
        ...letterOfReferenceEmails,
      ];
      if (files.length > 0) {
        this.setState({ files, fileSubmitted: true });
      }
    }

    if (apiRequestCallId == this.deleteCertificateId) {
      if (responseJson) {
        const updatedFiles = this.state.files.filter((file: any) => file.id !== file);

        this.setState({ deleteSuccess: true })
        this.setState({ files: updatedFiles });
        this.getuploadedCertificates()
      }
    }

    if (apiRequestCallId == this.uploadeDocId) {
      this.getuploadedCertificates()
      this.setState({ uploadSuccess: true })
    }
    if(apiRequestCallId==this.editCertificateid){
      this.setState({isEditUpload:false})
        this.getuploadedCertificates()
    }
  }
  
  parseTime = (time: string | null) => {
    if (!time) {
      return { hour: 9, minute: 0, period: 'AM' }; 
    }
    const [timePart, period] = time.split(' ');
    const [hour, minute] = timePart.split(':').map(Number);

    return { hour, minute, period };
  };


  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.deleteSuccess && !prevState.deleteSuccess) {
      setTimeout(() => {
        this.setState({ deleteSuccess: false });
      }, 3000);
    }
    if (this.state.uploadSuccess && !prevState.uploadSuccess) {
      setTimeout(() => {
        this.setState({ uploadSuccess: false });
      }, 3000);
    }
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
    this.fetchAttributes();
    if (
      prevState.introductionText !== this.state.introductionText ||
      prevState.workExpIdOne !== this.state.workExpIdOne ||
      prevState.eduIdOne !== this.state.eduIdOne ||
      prevState.skills.length !== this.state.skills.length ||
      prevState.teachinInterests.length !== this.state.teachinInterests.length ||
      prevState.files.length !== this.state.files.length ||
      prevState.selectedWorkingDays.length !== this.state.selectedWorkingDays.length ||
      prevState.selectedModalities.length !== this.state.selectedModalities.length ||
      prevState.selectedAgeGroups.length !== this.state.selectedAgeGroups.length ||
      prevState.selectedTimeShift !== this.state.selectedTimeShift ||
      prevState.selectedNatureJob !== this.state.selectedNatureJob ||
      prevState.distance !== this.state.distance
    ) {
      if (this.state.profileRoleId == 2) {
        this.setScaleValue();
      }
      else{
        this.setScaleValueAdmin();
      }
    }
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {

  };
  getCustomizableProfile = () => {

  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item?.value as string)?.trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
  };

  fetchProfileData = async () => {
  };

  fetchAttributes = async () => {
  };

  updateProfile = async () => {
  };
  handlePersonalInfo = () => {
    this.setState((prevState) => ({
      openPersonalInfo: !prevState.openPersonalInfo,
    }));
  };
  handleSInfo =()=>{
    this.setState((prevState) => ({
      openSinfo: !prevState.openSinfo,
    }));
  }
  toggleEditMode = () => {
    this.setState((prevState) => ({ editModeAcc: !prevState.editModeAcc }));
  };

  toggleEditModeAdd = () => {
    this.setState((prevState) => ({ editModeAdd: !prevState.editModeAdd }));
  };
  handleEditChange = (event: { target: { name: string; value: string; }; }) => {
    const { name, value } = event.target;
    let cleanedValue = value;
    let errorMessage = '';
  
    const characterOnlyFields = [
      'firstName', 'lastName', 'organization', 'profileCity', 
      'state', 'country', 's_city', 's_state', 'last_Name' ,'s_country'
    ];
  
    const numberOnlyFields = ['memberId', 'phoneNum', 'employee', 'capacity'];
    const zipCode = ['s_zip' , 'zipCode']
    if (characterOnlyFields.includes(name)) {
      cleanedValue = value.replace(/\d/g, ''); 
    }
  
    
    else if (numberOnlyFields.includes(name)) {
      cleanedValue = value.replace(/[^\d]/g, '');
  
      if (name === 'phoneNum' && cleanedValue.length > 10) {
        cleanedValue = cleanedValue.slice(0, 10); 
      }
      if(this.validatePhoneNum(cleanedValue) &&  name == 'phoneNum'){
        errorMessage = 'Phone Number must be 10 digits'
      }
      if (cleanedValue === '0000000000') {
        errorMessage = 'Please Enter a valid Phone Number'
      }
    }
    else if(zipCode.includes(name)){
      cleanedValue = value.replace(/[^\d-]/g, '');
    }
    this.setState({
      ...this.state,
      [name]: cleanedValue,
      [`${name}Error`]:errorMessage
    });
  };
  validatePhoneNum(value:string){
    return (value.length < 10 || value.length > 10) ? true:false
  }

  isValidEmail(email: string) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const test=  emailRegex.test(email);
    let text = 'Invalid email, please enter valid email.'
    this.setState({
      emailError : test ? '' : text
    })
    return test
  }
  handleResumeBox = () => {
    this.setState((prevState) => ({
      openResume: !prevState.openResume,
    }));
  };

  handleEdiDeleClose = () => {
    this.setState({ anchorEl: null });
  }

  handleThreedotSelect = (event: { currentTarget: null | HTMLElement; }) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleEditIntro = async () => {
    this.setState({ openIntroduction: true, introductionValueBox: false, isIntorEditClicked: true });
    this.handleEdiDeleClose();
  };
  handleResize = () => {
    if (window.innerWidth > 571 && this.state.isDrawerOpen) {
      this.toggleDrawer(false);
    }
  };
  getIntro = async () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIntroId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/abouts`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }

  handleDelete = async () => {

    this.setState({
      introductionText: '',
      openIntroduction: false,
      anchorEl: null,
      introductionValueBox: false
    });

    const header = {
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleIntroId = setIntroMsg.messageId;


    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/abouts/${this.state.introId}`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);

  };

  handleIntroSubmit = async () => {

    if (this.state.introductionText != '') {
      this.setState({ openIntroduction: false, introductionValueBox: true });
      if (!this.state.isIntorEditClicked) {

        const header = {
          "Content-Type": configJSON.searchApiContentType,
          token: this.state.token,
        };
        const body = {
          "data": {
            "type": "abouts",
            "attributes": {
              "account_id": Number(this.state.userId),
              "description": this.state.introductionText
            }
          }
        }

        const setIntroMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.uploadIntroId = setIntroMsg.messageId;

        setIntroMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_custom_forms/abouts`
        );

        setIntroMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        setIntroMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );

        setIntroMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );

        runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
        this.getIntro()
      }

      if (this.state.isIntorEditClicked) {
        const header = {
          "Content-Type": configJSON.searchApiContentType,
          token: this.state.token,
        };
        const body = {
          "data": {
            "type": "abouts",
            "attributes": {
              "account_id": Number(this.state.userId),
              "description": this.state.introductionText
            }
          }
        }

        const setIntroMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editItnroId = setIntroMsg.messageId;


        setIntroMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_custom_forms/abouts/${this.state.introId}`
        );

        setIntroMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        setIntroMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );

        setIntroMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );

        runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
      }
      this.setState({ isIntorEditClicked: false })
      this.getIntro()
      this.setState({ introError: '' })
    }
    else { this.setState({ introError: '*Please enter introduction' }) }
  };

  handleAddDetails = () => {
    this.setState({ openIntroduction: true });
  };

  handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ introductionText: event.target.value });
  };
  openEducationBox = () => {
    this.setState({ openEducation: true })
  }
 
  handleInputChange = (name:string, value:string|unknown) => {
    this.setState({
      currentEducation: {
        ...this.state.currentEducation,
        [name]: value,
      },
    });
  };
  handleWorkChange=(name:string,value:string)=>{
    this.setState({
      currentWorkExp: {
        ...this.state.currentWorkExp,
        [name]: value,
      },
    });
  }
  
  handleEducationSubmit = async () => {
    const { currentEducation, educationRecords, editIndex } = this.state;
    if (currentEducation.schoolName && currentEducation.course && currentEducation.specialization && currentEducation.textFieldValueEduFrom && currentEducation.textFieldValueEduTo) {
      const header = {
        token: this.state.token,
      };
      const formData = new FormData();
      formData.append("data[attributes][course]", currentEducation.course);
      formData.append("data[attributes][specialization]", currentEducation.specialization);
      formData.append("data[attributes][university]", currentEducation.schoolName);
      formData.append("data[attributes][account_id]", this.state.userId);
      formData.append("data[attributes][to_date]", currentEducation.textFieldValueEduTo);
      formData.append("data[attributes][from_date]", currentEducation.textFieldValueEduFrom);
  
      let apiEndpoint = `bx_block_custom_forms/qualifications`;
      let method = "POST";
      
      if (editIndex !== null) {
        apiEndpoint += `/${educationRecords[editIndex].id}`;
        method = "PUT";
      }
      
      const apiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.uploadEduId = apiMsg.messageId;
      apiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndpoint);
      apiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      apiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      apiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
      runEngine.sendMessage(apiMsg.id, apiMsg);
  
      if (editIndex !== null) {
        educationRecords[editIndex] = currentEducation;
      } else {
        educationRecords.push(currentEducation);
      }
  
      this.setState({
        educationRecords,
        currentEducation: {
          id:null,
          schoolName: '',
          course: '',
          specialization: '',
          textFieldValueEduFrom: '',
          textFieldValueEduTo: '',
        },
        openEducation: false,
        eduSubmitted: true,
        editIndex: null,
        eduOneError: '',
        calendarValueEduFrom:null,
        calendarValueEduTo:null,
      });
  
      this.getEducationDetails(); 
    } else {
      this.setState({ eduOneError: '*Please fill all the fields' });
    }
  };
  editEducation = (index: number|null) => {
    this.setState({
      currentEducation: { ...this.state.educationRecords[index as number] },
      openEducation: true,
      editIndex: index,
      eduSubmitted: false,
    });
  };
  editWorkExp = (index: number|null) => {
    this.setState({
      currentWorkExp: { ...this.state.workExpRecords[index as number] },
      openWorkexperience: true,
      editIndexWork: index,
      wrokExpOneSubmitted: false,
    });
  };
  handleDeleteIconClick = (educationIndex: number|null) => {
    this.setState({ 
      deleteEduConfirmOne: true,
      selectedEducationIndex: educationIndex 
    });
  };
  handleDeleteIconClickWork = (workIndex: number|null) => {
    this.setState({ 
      deleteWorkConfirm: true,
      selectedWorkIndex: workIndex 
    });
  };
  
  handleMoreEdu = () => {
    this.setState({
      currentEducation: {
        id:null,
        schoolName: '',
        course: '',
        specialization: '',
        textFieldValueEduFrom: '',
        textFieldValueEduTo: '',
      },
      openEducation: true,
      editIndex: null,
      eduSubmitted: false,
    });
  };
  handleMorework = () => {
    this.setState({
      currentWorkExp: {
        id:null,
        position: '',
        organization: '',
        jobResponsibility: '',
        experience: '',
        textFieldValue: '',
        textFieldValueTo: '',
      },
      openWorkexperience: true,
      editIndexWork: null,
      wrokExpOneSubmitted: false,
    });
  };
  handleDeleteEduConf = (index: any) => {
    this.setState({
      deleteEduConfirmOne: true,
      editIndex: index,
    });
  };
  
  
  handleEducOption = (event: { currentTarget: null | HTMLElement; }) => {
    this.setState({ anchorElEducation: event.currentTarget });
  }
  handleEditEducationone = () => {
    this.setState({ openEducation: true, eduSubmitted: false, editEduOneClicked: true })
  }
 


  onValueChange = (name: string, value: string | unknown | number) => {
    this.setState({ ...this.state, [name]: value });
  };
  
  deleteEduOne=()=>{
    this.setState({deleteEduConfirmOne:true})
  }

  getProfileData = () => {
    this.setState({
      loader:true
    })
    const header = {
      token: this.state.token,
    };

    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDataId = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/custom_forms/${this.state.userId}/show_profile`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }

  updateProfileData = () => {
    if(!this.isValidEmail(this.state.profileEmail)){
      this.setState({emailError : "Invalid email, please enter valid email."});
      return false
    }
    if(this.state.emailError !== '' || this.state.phoneNumError !== ''){
      return false
    }
    this.setState({ editModeAcc: false })
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    formData.append("data[attributes][email]", this.state.profileEmail);
    formData.append("data[attributes][first_name]", this.state.firstName);
    if (this.state.firstPhoneNumber != this.state.phoneNum) {
      formData.append("data[attributes][last_name]", this.state.last_Name);
    }
    formData.append("data[attributes][full_phone_number]", this.state.countryCode||1+this.state.phoneNum);
    formData.append("data[attributes][phone_number]", this.state.phoneNum);
    formData.append("data[type]", "email_account");

    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileAcc = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/custom_forms/${this.state.userId}/update_account`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }

  updateProfileDataSecond = () => {
    this.setState({ editModeAdd: false })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const formData = new FormData();
    formData.append("data[attributes][street_address]", this.state.street);
    formData.append("data[attributes][city]", this.state.profileCity);
    formData.append("data[attributes][state]", this.state.state);
    formData.append("data[attributes][zip_code]", this.state.zipCode);
    formData.append("data[attributes][country]", this.state.country);
    formData.append("data[type]", "email_account");

    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileAdd = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/custom_forms/${this.state.userId}/update_account`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }

  getEducationDetails = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEduId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/qualifications`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  handleDeleteEducationOne=()=>{
    const header = {
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletEduOne = setIntroMsg.messageId;


    setIntroMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_forms/qualifications/${this.state.selectedEducationIndex}`);

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    this.setState({schoolName:'',course:'',specialization:'',textFieldValueEduFrom:'',textFieldValueEduTo:'', deleteEduConfirmOne:false, eduSubmitted:false,secondEduSubmit:false})
  }


  navigateToHome = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobListing'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleWorkExp = (event: { currentTarget: null | HTMLElement; }) => {
    this.setState({ anchorElWorkExp: event.currentTarget });
  }
  openWorkExp = () => {
    this.setState({ openWorkexperience: true })
  }
  formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

 
  handleWorkExpSubmitOne = async () => {
    const { currentWorkExp, workExpRecords, editIndexWork } = this.state;
    if (
      currentWorkExp.organization &&
      currentWorkExp.position &&
      currentWorkExp.experience &&
      currentWorkExp.jobResponsibility &&
      currentWorkExp.textFieldValue &&
      currentWorkExp.textFieldValueTo
    ) {
      const startDate = new Date(this.state.calendarValue || Date.now());
      const endDate = new Date(this.state.calendarValueTo || Date.now());

      startDate.setDate(1);
      endDate.setDate(1);

      const formattedStartDate = this.formatDate(startDate);
      const formattedEndDate = this.formatDate(endDate);
      const header = {
        token: this.state.token,
        "Content-Type": "application/json",
      };
  
      const body = {
        data: {
          type: "work_experiences",
          attributes: {
            account_id: this.state.userId,
            organization: currentWorkExp.organization,
            position: currentWorkExp.position,
            experience: currentWorkExp.experience,
            job_responsibilities: currentWorkExp.jobResponsibility,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
          },
        },
      };
  
      let apiEndpoint = `bx_block_custom_forms/work_experiences`;
      let method = "POST";
  
      if (editIndexWork !== null) {
        apiEndpoint += `/${workExpRecords[editIndexWork].id}`;
        method = "PUT";
      }
  
      const apiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.uploadWorkExpId = apiMsg.messageId;
      apiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndpoint);
      apiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      apiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)); 
      apiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
      runEngine.sendMessage(apiMsg.id, apiMsg);
  
      if (editIndexWork !== null) {
        workExpRecords[editIndexWork] = currentWorkExp;
      } else {
        workExpRecords.push(currentWorkExp);
      }
  
      this.setState({
        workExpRecords,
        currentWorkExp: {
          id: null,
          organization: '',
          position: '',
          experience: '',
          textFieldValue: '',
          textFieldValueTo: '',
          jobResponsibility: '',
        },
        openWorkexperience: false,
        wrokExpOneSubmitted: true,
        editIndexWork: null,
        workOneError: '',
        calendarValueTo:null,
        calendarValue:null,
      });
  
      this.getWorkExp();
    } else {
      this.setState({ workOneError: '*Please fill all the fields' });
    }
  };

  handleDeleteWorkExp = () => {
    const header = {
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteWorkOneId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/work_experiences/${this.state.selectedWorkIndex}`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
this.setState({deleteWorkConfirm:false})
  }

  getWorkExp = async () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWorkExpId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/work_experiences`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
 
  openSkills = () => {
    this.setState({ openSkills: true })
  }

  handleSkillChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newSkill: event.target.value });
  };

  addSkill = () => {
    const { newSkill, token, userId } = this.state;
    this.setState({ addskillClicked: true, skillError: '' });

    if (newSkill) {
      const header = {
        'Content-Type': configJSON.searchApiContentType,
        token: token,
      };
      const body = {
        data: {
          type: 'work_experiences',
          attributes: {
            account_id: userId,
            name: newSkill,
          },
        },
      };

      const setIntroMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.addSkillsId = setIntroMsg.messageId;

      setIntroMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_forms/skills`);

      setIntroMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      setIntroMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

      setIntroMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'POST');

      runEngine.sendMessage(setIntroMsg.id, setIntroMsg);

      this.setState({ newSkill: '', skillError: '', addskillClicked: false });
    } else {
      this.setState({ skillError: 'Please enter a skill name' });
    }
  };

  submitSkills = () => {
    const { addskillClicked, newSkill, skills } = this.state;

    if (skills.length > 0) {
      this.setState({ showTextField: false, skillsPresent: true, skillError: '' });
      return;
    }

    if (newSkill.trim() == '') {
      this.setState({ skillError: 'Enter a value' });
      return;
    }

    if (addskillClicked) {
      this.setState({ showTextField: false, skillsPresent: true, addskillClicked: false, skillError: '' });
      this.getSkills();
    } else {
      this.setState({ skillError: 'Please add the skill first' });
    }
  };



  deleteSkill = async (skillId: number) => {
    const { token } = this.state;

    const header = {
      'Content-Type': configJSON.searchApiContentType,
      token: token,
    };

    const deleteMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    deleteMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_forms/skills/${skillId}`);

    deleteMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    deleteMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'DELETE');

    runEngine.sendMessage(deleteMsg.id, deleteMsg);

    const storedSkillIds = JSON.parse(await storage.get('skillIds') || '[]');
    const newStoredSkillIds = storedSkillIds.filter((id: number) => id !== skillId);
    storage.set('skillIds', JSON.stringify(newStoredSkillIds));
    this.setState((prevState) => ({
      skills: prevState.skills.filter((skill) => skill.id !== skillId),
    }));

  };

  handleEdiDeleSkills = () => {
    this.setState({ anchorElSkills: null });
  }
  handleEditSkills = () => {
    this.setState({ showTextField: true, openSkills: true, anchorElSkills: null })
  }
  handleSkills = (event: { currentTarget: null | HTMLElement; }) => {
    this.setState({ anchorElSkills: event.currentTarget });
  }
  getSkills = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSkillsId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/skills`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }

  handleInterest = (event: { currentTarget: null | HTMLElement; }) => {
    this.setState({ anchorElInnterest: event.currentTarget });
  }
  handleEdiDeleInterest = () => {
    this.setState({ anchorElInnterest: null });
  }
  handleEditInterest = () => {
    this.setState({ showTextFieldInterest: true, openTeaching: true, anchorElInnterest: null })
  }

  openInterest = () => {
    this.setState({ openTeaching: true })
  }
  handleIntrestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newInterest: event.target.value });
  };

  addIntrest = () => {
    const { newInterest, token, userId } = this.state;
    this.setState({ addInterestClicked: true, interestError: '' });

    if (newInterest) {
      const header = {
        'Content-Type': configJSON.searchApiContentType,
        token: token,
      };
      const body = {
        data: {
          type: 'teaching_interests',
          attributes: {
            account_id: userId,
            name: newInterest,
          },
        },
      };

      const setIntroMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.addIntId = setIntroMsg.messageId;

      setIntroMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_forms/teaching_interests`);

      setIntroMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      setIntroMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

      setIntroMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'POST');

      runEngine.sendMessage(setIntroMsg.id, setIntroMsg);

      this.setState({ newInterest: '', interestError: '', addInterestClicked: false });
    } else {
      this.setState({ interestError: 'Please enter an interest' });
    }
  };

  submitIntrest = () => {
    const { addInterestClicked, newInterest, teachinInterests } = this.state;

    if (teachinInterests.length > 0) {
      this.setState({ showTextFieldInterest: false, interestPresent: true, interestError: '' });
      return;
    }

    if (newInterest.trim() == '') {
      this.setState({ interestError: 'Enter a value' });
      return;
    }

    if (addInterestClicked) {
      this.setState({ showTextFieldInterest: false, interestPresent: true, addInterestClicked: false, interestError: '' });
      this.getIntrest();
    } else {
      this.setState({ interestError: 'Please add the interest first' });
    }
  };

  getIntrest = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIntrestId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/teaching_interests`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }

  deleteIntrest = async (intrestId: number) => {
    const { token } = this.state;

    const header = {
      'Content-Type': configJSON.searchApiContentType,
      token: token,
    };

    const deleteMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    deleteMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_forms/teaching_interests/${intrestId}`);

    deleteMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    deleteMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'DELETE');

    runEngine.sendMessage(deleteMsg.id, deleteMsg);

    const storedSkillIds = JSON.parse(await storage.get('intrestsss') || '[]');
    const newStoredSkillIds = storedSkillIds.filter((id: number) => id !== intrestId);
    storage.set('intrestsss', JSON.stringify(newStoredSkillIds));
    this.setState((prevState) => ({
      teachinInterests: prevState.teachinInterests.filter((teachinInterests) => teachinInterests.id !== intrestId),
    }));

  };
  handlejobPrefer = () => {
    this.setState((prevState) => ({
      openJobPrefer: !prevState.openJobPrefer,
    }));
  }

  savedAddressClick = (providerType: string, index: number) => {
    this.setState((prevState) => {
      const newSavedAddresses = {
        ...prevState.savedAddresses,
        [providerType]: !prevState.savedAddresses[providerType],
      };
      const newSelectedModalities = Object.keys(newSavedAddresses).filter(key => newSavedAddresses[key]);
      const newSelectedModalityIndices = newSavedAddresses[providerType]
        ? [...prevState.selectedModalityIndices, index]
        : prevState.selectedModalityIndices.filter(i => i !== index);
      return {
        savedAddresses: newSavedAddresses,
        selectedModalities: newSelectedModalities,
        selectedModalityIndices: newSelectedModalityIndices,
      };
    });
  };
  ageGroupClick = (providerType: string, index: number) => {
    this.setState((prevState) => {
      const newAgeGroups = {
        ...prevState.ageGroups,
        [providerType]: !prevState.ageGroups[providerType],
      };
      const newSelectedAgeGroups = Object.keys(newAgeGroups).filter(key => newAgeGroups[key]);
      const newSelectedAgeGroupIndices = newAgeGroups[providerType]
        ? [...prevState.selectedAgeGroupIndices, index]
        : prevState.selectedAgeGroupIndices.filter(i => i !== index);
      return {
        ageGroups: newAgeGroups,
        selectedAgeGroups: newSelectedAgeGroups,
        selectedAgeGroupIndices: newSelectedAgeGroupIndices,
      };
    });
  };

  handleCheckboxChange = (providerType:any, index:any) => {
    const dayId = index + 1;
    const workingDays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    this.setState(prevState => {
      const { selectedWorkingDays, selectedWorkingDayIndices } = prevState;
      const isChecked = selectedWorkingDays.includes(providerType);
      if (isChecked) {
        return {
          selectedWorkingDays: selectedWorkingDays.filter(day => day !== providerType),
          selectedWorkingDayIndices: selectedWorkingDayIndices.filter(id => id !== dayId),
        };
      } else {
        const days =[...selectedWorkingDays, providerType].sort((a, b) => workingDays.indexOf(a) - workingDays.indexOf(b))
        const indices = [...selectedWorkingDayIndices, dayId].sort((a, b) => a - b)
        return {
          selectedWorkingDays: days,
          selectedWorkingDayIndices: indices,
        };
      }
    } , ()=>{
      this.saveAvailbility()
    });
  };
  timeShiftChange = (event: string, index: string) => {
    this.setState({ selectedTimeShift: event, selectedTimeShiftId: index });
  };
  natureJobChange = (event: string, index: string) => {
    this.setState({ selectedNatureJob: event, selectedNatureJobId: index });
  };
  handleDistance = (event: string) => {
    this.setState({ distance: event })
  }
  savePreferences = () => {
    this.setState({ displaySummary: true, saveSuccessPrefer: true });
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    this.state.selectedModalityIndices.forEach((index) => {
      formData.append("data[attributes][preferred_modality_ids][]", index.toString());
    });
    this.state.selectedAgeGroupIndices.forEach((index) => {
      formData.append("data[attributes][preferred_age_group_ids][]", index.toString());
    });
    this.state.selectedWorkingDayIndices.forEach((index) => {
      formData.append("data[attributes][preferred_working_days_ids][]", index.toString());
    });
    formData.append("data[attributes][preferred_time_shift_ids][]", this.state.selectedTimeShiftId);
    formData.append("data[attributes][preferred_nature_of_job_ids][]", this.state.selectedNatureJobId);
    formData.append("data[attributes][preferred_travel_distance]", this.state.distance);

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadJobPreferId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_preferences/update_job_preferences/${this.state.userId}`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  };

  editPreferences = () => {
    this.setState({ displaySummary: false });
  };
  getJobPreferences = async () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getJobPreferId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_preferences/job_preference_list/${this.state.userId}`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  handleAvailibilty = () => {
    this.setState((prevState) => ({
      openAvailbility: !prevState.openAvailbility,
    }));
  }
  editAvailability = () => {
    this.setState({ editAvailbility: true })
  }
  saveAvailbility = () => {
    this.setState({ editAvailbility: false })
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };
    const body = {
      "data": {
        "attributes": {
          "preferred_working_days_ids": this.state.selectedWorkingDayIndices
        }
      }
    }

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadWorkingId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/account_working_day_times/${this.state.userId}/update_preferred_working_days`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }


  dayToggle = (day: string) => {
    this.setState((prevState) => {
      const newWorkingDays = {
        ...prevState.workingDays,
        [day]: !prevState.workingDays[day],
      };

      const workingDayIdMap: { [key: string]: number } = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7,
      };

      const working_day_id = workingDayIdMap[day];
      const full_day = newWorkingDays[day];

      const header = {
        "Content-Type": configJSON.searchApiContentType,
        token: prevState.token,
      };
      const body = {
        "working_day_times": [
          {
            "working_day_id": working_day_id,
            "full_day": full_day,
          },
        ],
      };

      const setIntroMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.setTimingId = setIntroMsg.messageId;
      setIntroMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_forms/account_working_day_times/${this.state.userId}/update_working_day_times`);

      setIntroMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
      );
      setIntroMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
      );

      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      runEngine.sendMessage(setIntroMsg.id, setIntroMsg);

      return {
        workingDays: newWorkingDays,
      };
    });
  };


  handleTimeChange = (day: string, type: 'start' | 'end', value: Time) => {
    this.setState((prevState) => ({
      workTimes: {
        ...prevState.workTimes,
        [day]: {
          ...prevState.workTimes[day],
          [type]: value
        }
      }
    }));
  };

  clickTime = (day: string) => {
    this.setState({ startClicked: true, activeDay: day });
  };

  clickEndTime = (day: string) => {
    this.setState({ endClicked: true, activeDay: day });
  };

  closeTimeDialog = () => {
    this.setState({ startClicked: false, endClicked: false, activeDay: null });
  };

  saveStartTime = (day: string, startTime: { hour: number; minute: number; period: 'AM' | 'PM' } , endTime?: { hour: number; minute: number; period: 'AM' | 'PM' }) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };
    const dayIndex = this.getDayIndex(day);
    const formattedStartTime = `${startTime.hour}:${startTime.minute.toString().padStart(2, '0')} ${startTime.period}`;
    const body = {
      "working_day_times": [{
        "working_day_id": dayIndex,
        "start_time": formattedStartTime, "full_day": startTime.hour == 0 || endTime?.hour == 0? true : false
      },
      ]
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.setTimingId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_forms/account_working_day_times/${this.state.userId}/update_working_day_times`);

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    this.closeTimeDialog()
  };
  saveEndTime = (day: string, endTime: { hour: number; minute: number; period: 'AM' | 'PM' }, startTime: { hour: number; minute: number; period: 'AM' | 'PM' }) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };
    const dayIndex = this.getDayIndex(day);
    const formattedEndTime = `${endTime.hour}:${endTime.minute.toString().padStart(2, '0')} ${endTime.period}`
    const formattedStartTime = `${startTime.hour}:${startTime.minute.toString().padStart(2, '0')} ${startTime.period}`
    const body = {
      "working_day_times": [
        {
          "working_day_id": dayIndex,
          "end_time": formattedEndTime,
          "start_time": formattedStartTime,
          "full_day": endTime.hour == 0 || startTime.hour == 0 ? true : false
        },
      ]
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setTimingId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/account_working_day_times/${this.state.userId}/update_working_day_times`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    this.closeTimeDialog()
  };
  getDayIndex = (day: string) => {
    const dayMap: { [key: string]: number } = {
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6,
      'Sunday': 7,
    };
    return dayMap[day];
  };

  showAllTimeData = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTimeId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/account_working_day_times/${this.state.userId}/list_all_working_day_times`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl2: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl2: null,
    });
  };


  handleClickTo = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl2To: event.currentTarget,
    });
  };

  handleCloseTo = () => {
    this.setState({
      anchorEl2To: null,
    });
  };


  handleClickSecFrom = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElSecFrom: event.currentTarget,
    });
  };

  handleCloseSecFrom = () => {
    this.setState({
      anchorElSecFrom: null,
    });
  };


  handleClickSecTo = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElSecTo: event.currentTarget,
    });
  };

  handleCloseSecTo = () => {
    this.setState({
      anchorElSecTo: null,
    });
  };


  handleClickEduFrom = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElEduFrom: event.currentTarget,
    });
  };

  handleCloseEduFrom = () => {
    this.setState({
      anchorElEduFrom: null,
    });
  };


  handleClickEduTo = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElEduTo: event.currentTarget,
    });
  };

  handleCloseEduTo = () => {
    this.setState({
      anchorElEduTo: null,
    });
  };


  handleClickSecEduTo = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElSecEduTo: event.currentTarget,
    });
  };

  handleCloseSecEduTo = () => {
    this.setState({
      anchorElSecEduTo: null,
    });
  };


  handleClickSecEduFrom = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElSecEduFrom: event.currentTarget,
    });
  };

  handleCloseSecEduFrom = () => {
    this.setState({
      anchorElSecEduFrom: null,
    });
  };


  handleDateChanges = (stateKey: string, textFieldKey: string, anchorKey: string) => (date: Date) => {
    const monthYear = date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
    this.setState(prevState => ({
      currentEducation: {
        ...prevState.currentEducation,
        [textFieldKey]: monthYear
      },
      [stateKey]: date, 
      [anchorKey]: null,
    }as any));
  };
  handleDateChangesWork = (stateKey: string, textFieldKey: string, anchorKey: string) => (date: Date) => {
    const monthYear = date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
    this.setState(prevState => ({
      currentWorkExp: {
        ...prevState.currentWorkExp,
        [textFieldKey]: monthYear
      },
      [stateKey]: date, 
      [anchorKey]: null,
    }as any));
  };
  
  handleCertificatBox = () => {
    this.setState((prevState) => ({
      openCertificate: !prevState.openCertificate,
    }));
  };
  handleEditLogo = () =>{
    this.setState({
      iseditLogo: true, 
      anchorElCertificate:null
    })
  }
  handleDeleteLogo = async (id:any) =>{
  
      const header = {
        token: this.state.token,
      };
      const schoolid = await storage.get("loginId")
  
      const setIntroMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
      this.deleteLogoId = setIntroMsg.messageId;
    
        setIntroMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/account_block/school_details/${id}/delete_school_logo`
        );
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "DELETE"
      );
  
      runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
      this.getSchoolData(schoolid)
      this.setState({
        anchorElCertificate:null
      })
  }
  openEditShareWith = () => {
    this.setState({ openEditShareWith: true })
  }
  handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedShareWith: event.target.value, openEditShareWith: false });
  };
  handleOptionClick = (option: string) => {
    this.setState({ selectedShareWith: option }, () =>
      storage.set("shareWith", this.state.selectedShareWith)
    );
  }
  handleSaveShareWith = async () => {
    this.setState({ openEditShareWith: false })
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const shareWithId = await storage.get('shareWith')
    const formData = new FormData();
    if (shareWithId == 'Share with Palmetto') {
      formData.append("data[attributes][document_sharing_ids][]", '1');
    }
    else if (shareWithId == 'Share with Job Providers') {
      formData.append("data[attributes][document_sharing_ids][]", '2');
    }
    else if (shareWithId == 'Share with both (Palmetto and Job Providers)') {
      formData.append("data[attributes][document_sharing_ids][]", '3');
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editShareWithId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/update_account`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleFileUploadClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
    this.setState({isEditUpload:true})
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const fileType = file.type;
      const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf','application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-word.document.macroEnabled.12',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
      ];
      if (!validTypes.includes(fileType)) {
        alert('Only PNG, JPG, JPEG, PDF and DOC files are allowed.');
      } else {
        const uploadDate = new Date().toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short'
        });
        this.setState({ currentFile: file, currentUploadDate: uploadDate, fileSubmitted: false , currentFileUrl:''});
      }
    }
  };

  handleFileChangeAdmin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const fileType = file.type;
      const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
      if (!validTypes.includes(fileType)) {
        alert('Only PNG, JPG, and JPEG files are allowed.');
      } else {
        this.setState({ currentFile: file, fileSubmitted: false  , iseditLogo :false});
      }
    }
  };

  handleCertificateName = (value: string) => {
    this.setState({ currentCertificateName: value });
  };

  handleSubmitFile = () => {
    if (this.state.isEditing || this.state.isReuploading) {
      this.handleSubmitEdit();
    }
    else {
      
      const { currentCertificateName, currentFile, files } = this.state;
    if(currentCertificateName=='') this.setState({certificateNameError:"Please enter certificate name"})
      if(this.state.textFieldValueDocStart=='')this.setState({stardateError:'Select start date'})
        if(this.state.textFieldValueDocEnd=='')this.setState({enddateError:'Select end date'})
      if (currentCertificateName && currentFile && this.state.textFieldValueDocStart && this.state.textFieldValueDocEnd) {
        this.setState({certificateNameError:'',stardateError:'',enddateError:''})
        const uploadDate = new Date().toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short'
        });

        const header = {
          "Content-Type": configJSON.apiContentType,
          token: this.state.token,
        };
        const formData = new FormData();
        formData.append("certificate[name]", currentCertificateName);
        formData.append("certificate[files][]", currentFile);
        formData.append("certificate[start_date]", this.state.textFieldValueDocStart);
        formData.append("certificate[end_date]", this.state.textFieldValueDocEnd);


        const getProfileMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.uploadeDocId = getProfileMsg.messageId;

        getProfileMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );

        getProfileMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_datastorage/certifications/${this.state.userId}/add_certificates`
        );

        getProfileMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        getProfileMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `POST`
        );

        runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
        this.getuploadedCertificates()
        this.setState({
          files: [...files, {
            certificateName: currentCertificateName, file: currentFile, uploadDate,
            url: undefined,
            id: 0,
            attachment_type: "",
            certification_id: 0,
            attachment_id: 0,
            start_date:'',
            end_date:'',
            size:''
          }],
          currentCertificateName: '',
          currentFile: null,
          fileSubmitted: true,
          currentUploadDate: '',
          textFieldValueDocStart:'',
          textFieldValueDocEnd:'',
          calendarValueDocEnd:null,
          calendarValueDocStart:null,
          SelectedSize:''
        });
      }
    }
  };

  addeNewFile = () => {
    this.setState({ fileSubmitted: false }, () => {
      setTimeout(() => {
        this.scrollToSection(this.certificateRef);
      }, 0);
    });
  };

  getuploadedCertificates = async () => {

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token,
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCertificates = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_datastorage/certifications/${this.state.userId}`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }


  handleEditPopUp = (event: React.MouseEvent<HTMLElement>, fileId: number) => {
    this.setState({
      anchorElCertificate: event.currentTarget,
      anchorElFileId: fileId,
    });
  };

  handleEditPopUpAdmin = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElCertificate: event.currentTarget,
    });
  };

  handleEditPopUpClose = () => {
    this.setState({
      anchorElCertificate: null,
      anchorElFileId: null,
    });
  };
  handleDeletCerificate = (attachment_type: string, certification_id: number, attachment_id: number) => {
    const header = {
      token: this.state.token,
    };

    const setIntroMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCertificateId = setIntroMsg.messageId;

    if (certification_id > 0) {
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/delete_certification_attachment?account_id=${this.state.userId}&attachment_type=${attachment_type}&certification_id=${certification_id}&attachment_id=${attachment_id}`
      );
    }
    else {
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/delete_certification_attachment?account_id=${this.state.userId}&attachment_type=${attachment_type}&attachment_id=${attachment_id}`
      );
    }

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    this.setState((prevState) => {
      const updatedFiles = prevState.files.filter(file => file.attachment_id !== attachment_id);
      return {
        files: updatedFiles
      };
    });
  };
  handleSavedJob = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SavedJob'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleSavedJobsApp = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'MyJobsApplication'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleEditClick = (certificateName: string, file: File, certificate_id: number, attachment_id: number, uploadDate: string, fileUrl:string, attachment_type:string, start_date:string|null, end_date:string|null,size:string) => {
    this.setState({
      isEditing: true,
      isReuploading: false,
      currentCertificateName: certificateName,
      currentFile: file,
      fileSubmitted: false,
      selectedCertificateId: certificate_id,
      selectedAttachmentId: attachment_id,
      currentUploadDate: uploadDate,
      currentFileUrl:fileUrl,
      selectedFileType:attachment_type,
      textFieldValueDocStart : this.formatDateDoc(start_date),
      textFieldValueDocEnd: this.formatDateDoc(end_date),
      SelectedSize:size
    },
    () => {
      setTimeout(() => {
        this.scrollToSection(this.certificateRef);
      }, 0);
    });
  };
  formatDateDoc=(dateString: string|null)=>{
    if(dateString==null){
      return ''
    }
    else{
    const [day, month, year] = dateString.split('/'); 

    const monthNames = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
    ];
      const monthName = monthNames[parseInt(month) - 1];
  
    return `${day} ${monthName} ${year}`;
  }
  }

  handleReuploadClick = (certificateName: string, certificate_id: number, attachment_id: number, attachment_type:string) => {
    this.setState({
      isEditing: false,
      isReuploading: true,
      currentCertificateName: certificateName,
      currentFile: null,
      fileSubmitted: false,
      selectedCertificateId: certificate_id,
      selectedAttachmentId: attachment_id,
      selectedFileType:attachment_type
    },() => {
      setTimeout(() => {
        this.scrollToSection(this.certificateRef);
      }, 0);
    });
  };
  handleViewClick = (fileUrl: string) => {
    this.setState({anchorElCertificate:null})
    window.open(fileUrl, '_blank')
};


  handleSubmitEdit = () => {
    const { currentCertificateName, currentFile, selectedCertificateId } = this.state;

    if (currentCertificateName && currentFile) {
      const header = {
        token: this.state.token,
      };
      const formData = new FormData();
      if (selectedCertificateId > 0) {
        formData.append("certificate_id", selectedCertificateId.toString());
        formData.append("certificate[name]", currentCertificateName);
        formData.append("certificate[start_date]", this.state.textFieldValueDocStart);
        formData.append("certificate[end_date]", this.state.textFieldValueDocEnd);
        if(this.state.isEditUpload && currentFile.size!=0){

          formData.append("certificate[files][]", currentFile);
        }
      }
      if (selectedCertificateId == 0) {
        this.otherFormData(formData)
      }

      const getProfileMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editCertificateid = getProfileMsg.messageId;

      getProfileMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      getProfileMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_datastorage/certifications/${this.state.userId}/edit_certificates`
      );

      getProfileMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      getProfileMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `PUT`
      );

      runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
      this.setState({
        currentCertificateName: '',
        currentFile: null,
        fileSubmitted: true,
        isEditing: false,
        isReuploading: false,
        selectedCertificateId: 0,
        selectedAttachmentId: 0,
        currentUploadDate: '',
        selectedFileType:'',
      });
    }
    this.setState({
      isEditing: false,
      isReuploading: false,
      currentCertificateName: '',
      currentFile: null,
      textFieldValueDocStart:'',
      textFieldValueDocEnd:'',
      SelectedSize:''
    });
   
  };
  otherFormData=(formData:any)=>{
  const { currentCertificateName, currentFile, selectedCertificateId } = this.state;
  if (this.state.selectedFileType == 'upload_resumes') { 
    formData.append("data[attributes][upload_resumes_type]", currentCertificateName);
  formData.append("data[attributes][start_date]", this.state.textFieldValueDocStart);
    formData.append("data[attributes][end_date]", this.state.textFieldValueDocEnd);
    if(this.state.isEditUpload){
      formData.append("data[attributes][upload_resumes][]", currentFile); 
    } 
  }
  if (this.state.selectedFileType == 'letters_of_interests') {
    formData.append("data[attributes][letters_of_interests_type]", currentCertificateName);
    formData.append("data[attributes][start_date]", this.state.textFieldValueDocStart);
    formData.append("data[attributes][end_date]", this.state.textFieldValueDocEnd);
    if(this.state.isEditUpload){ formData.append("data[attributes][letters_of_interests][]", currentFile);
    }
  }
  if (this.state.selectedFileType == 'credentials') { formData.append("data[attributes][credentials_type]", this.state.currentCertificateName);
formData.append("data[attributes][start_date]", this.state.textFieldValueDocStart);
  formData.append("data[attributes][end_date]", this.state.textFieldValueDocEnd);
if(this.state.isEditUpload && this.state.currentFile){
  formData.append("data[attributes][credentials][]", this.state.currentFile);}
}
if (this.state.selectedFileType == 'transcripts') {
formData.append("data[attributes][transcripts_type]", this.state.currentCertificateName);
formData.append("data[attributes][start_date]", this.state.textFieldValueDocStart);
  formData.append("data[attributes][end_date]", this.state.textFieldValueDocEnd); if(this.state.isEditUpload && this.state.currentFile){ formData.append("data[attributes][transcripts][]", this.state.currentFile);
}
}
if (this.state.selectedFileType == 'letter_of_reference_emails') {formData.append("data[attributes][letter_of_reference_emails_type]", this.state.currentCertificateName);
formData.append("data[attributes][start_date]", this.state.textFieldValueDocStart);
  formData.append("data[attributes][end_date]", this.state.textFieldValueDocEnd);
if(this.state.isEditUpload && this.state.currentFile){
formData.append("data[attributes][letter_of_reference_emails][]", this.state.currentFile); }
}
if (this.state.selectedFileType == 'note_pages') { formData.append("data[attributes][note_pages_type]", this.state.currentCertificateName);
  formData.append("data[attributes][start_date]", this.state.textFieldValueDocStart);
  formData.append("data[attributes][end_date]", this.state.textFieldValueDocEnd);
if(this.state.isEditUpload && this.state.currentFile){
formData.append("data[attributes][note_pages][]", this.state.currentFile);
}
}
}
handleImageClick = () => {
  if (this.profileInputRef.current) {
    this.profileInputRef.current.click();
  }
}

handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ prfilePic: reader.result as string });
      const header = {
      };
      const formData = new FormData();
        formData.append("data[attributes][profile_image]", file);
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.editProfilepic = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/accounts/${this.state.userId}/update_account`
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `PUT`
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    reader.readAsDataURL(file);
  } 
}
setScaleValue = () => {
  let newValue = 2;
  let emptyProfile=[]
  const resumeFields = [
    this.state.introductionText,
    this.state.workExpRecords.length > 0,
    this.state.educationRecords.length > 0,
    this.state.skills.length > 0,
    this.state.teachinInterests.length > 0
  ];

  if (resumeFields.every(Boolean)) {
    newValue += 2;
  } else {
    emptyProfile.push("Add Resume");
  }
  if (this.state.files.length > 0) {
    newValue += 2;
  } else {
    emptyProfile.push("Add Certificates");
  }

  if (this.state.selectedWorkingDays.length > 0) {
    newValue += 2;
  } else {
    emptyProfile.push("Add Availability");
  }

  const jobPrefFields = [
    this.state.selectedModalities.length > 0,
    this.state.selectedAgeGroups.length > 0,
    this.state.selectedTimeShift !== '',
    this.state.selectedNatureJob !== '',
    this.state.distance !== ''
  ];

  if (jobPrefFields.every(Boolean)) {
    newValue += 2;
  } else {
    emptyProfile.push("Add Job Preferences");
  }

  this.setState({ sliderValue: newValue, emptyProfile });

}

setScaleValueAdmin = () => {
  let newValue = 2;
  let emptyProfile = []
  const locationFields = [
    this.state.s_city != '',
    this.state.s_country  != '',
    this.state.s_state != '',
    this.state.s_zip != '',
    this.state.s_street != ''
  ]
  const resumeFields = [
    this.state.introductionText
  ];

  if (resumeFields.every(Boolean)) {
    newValue += 2;
  } else {
    emptyProfile.push("Add School Bio");
  }

  if (this.state.currentFileUrl) {
    newValue += 2;
  } else {
    emptyProfile.push("Add School Logo");
  }

  const jobPrefFields = [
    this.state.selectedModalities.length > 0,
    this.state.selectedAgeGroups.length > 0,
    this.state.selectedTimeShift !== '',
    this.state.selectedNatureJob !== '',
    this.state.distance !== ''
  ];

  if (jobPrefFields.every(Boolean)) {
    newValue += 2;
  } else {
    emptyProfile.push("Add Job Preferences");
  }

  if (locationFields.every(Boolean)) {
    newValue += 2;
  } else {
    emptyProfile.push("Add School location")
  }

  this.setState({ sliderValue: newValue, emptyProfile });
}

handleStartCalender = (event: React.MouseEvent<HTMLElement>) => {
  this.setState({
    anchorElDocStart: event.currentTarget,
  });
};

handleCloseDocanchorElDocStart = () => {
  this.setState({
    anchorElDocStart: null,
    anchorElDocEnd:null
  });
};
handleDateChangesdoc = (stateKey: string, textFieldKey: string, anchorKey: string) => (date: Date) => {
  const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
  this.setState({
    [stateKey]: date,
    [textFieldKey]: formattedDate,
    [anchorKey]: null,
  } as any);
  if (stateKey === "calendarValueDocStart") {
    this.setState({
      textFieldValueDocEnd : "",
      calendarValueDocEnd:null
    })
  }
};
handleendCalender = (event: React.MouseEvent<HTMLElement>) => {
  this.setState({
    anchorElDocEnd: event.currentTarget,
  });
};
resumeLink=()=>{this.scrollToSection(this.resumeRef);this.setState({ openResume: true })}
certificateLink=()=>{ this.scrollToSection(this.certificateRef);this.setState({ openCertificate: true }) }
preferLink=()=> { this.scrollToSection(this.jobPreferRef);this.setState({ openJobPrefer: true }) }
availabilityLink=()=> { this.scrollToSection(this.availabilityRef);this.setState({ openAvailbility: true }) }
personalLink=()=>{ this.scrollToSection(this.personalInfoRef);this.setState({ openPersonalInfo: true }) }
personalInfoClick=()=>{this.scrollToSection(this.personalRef);this.setState({openSinfo:true})};

getCount = () => {

  const header = {
    "Content-Type": configJSON.searchApiContentType,
    token: this.state.token,
  };

  const setIntroMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.countDataId = setIntroMsg.messageId;

  setIntroMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_joblisting/job_creations/jobs_count`
  );

  setIntroMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  setIntroMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
}

getAdminCount = () => {

  const header = {
    "Content-Type": configJSON.searchApiContentType,
    token: this.state.token,
  };

  const setIntroMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.countDataAdminId = setIntroMsg.messageId;

  setIntroMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_joblisting/job_creations/school_admin_jobs`
  );

  setIntroMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  setIntroMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
}
toggleDrawer = (isOpen: boolean) => {
  this.setState({ isDrawerOpen: isOpen });
};

sendData = ()=>{
  const { currentFile } = this.state
  const formData = new FormData();

  formData.append("school_detail[introduction]", this.state.introductionText);
  formData.append("school_detail[street]", this.state.s_street);
  formData.append("school_detail[city]", this.state.s_city);
  formData.append("school_detail[state]", this.state.s_state);
  formData.append("school_detail[country]", this.state.s_country);
  formData.append("school_detail[zip_code]", this.state.s_zip);

  if (currentFile) {
    formData.append("school_detail[logo]", currentFile);
  }
  if (this.state.selectedModalityIndices.length > 0) {
    this.state.selectedModalityIndices.forEach((index) => {
      formData.append("school_detail[preferred_modality_ids][]", index.toString());
    });
  } else {
    formData.append("school_detail[preferred_modality_ids][]", JSON.stringify([]));
  }

  if (this.state.selectedAgeGroupIndices.length > 0) {
    this.state.selectedAgeGroupIndices.forEach((index) => {
      formData.append("school_detail[preferred_age_group_ids][]", index.toString());
    });
  } else {
    formData.append("school_detail[preferred_age_group_ids][]", JSON.stringify([]));
  }

  if (this.state.selectedWorkingDayIndices.length > 0) {
    this.state.selectedWorkingDayIndices.forEach((index) => {
      formData.append("school_detail[preferred_working_days_ids][]", index.toString());
    });
  } else {
    formData.append("school_detail[preferred_working_days_ids][]", JSON.stringify([]));
  }
  formData.append("school_detail[preferred_time_shift_ids][]", this.state.selectedTimeShiftId);
  formData.append("school_detail[preferred_nature_of_job_ids][]", this.state.selectedNatureJobId);
  formData.append("school_detail[travel_distance]", this.state.distance);
  return formData
}

handleSubmitFromAdmin = async () => {
  const formData= this.sendData()
  const header = {
    "Content-Type": configJSON.apiContentType,
    token: await storage.get("loginToken"),
  };

  const getProfileMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.postForSchoolId = getProfileMsg.messageId;

  getProfileMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  getProfileMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/account_block/school_details`
  );

  getProfileMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  getProfileMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `POST`
  );

  runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
this.clearState()
}


handleUpdateFromAdmin = async () => {
  const formData= this.sendData()
  const sid = await storage.get("schoolid")
  const header = {
    "Content-Type": configJSON.apiContentType,
    token: this.state.token,
  };
  
  const putSchoolProfile = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.putForSchoolId = putSchoolProfile.messageId;

  putSchoolProfile.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  putSchoolProfile.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/account_block/school_details/${sid}`
  );

  putSchoolProfile.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  putSchoolProfile.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `PUT`
  );

  runEngine.sendMessage(putSchoolProfile.id, putSchoolProfile);
  this.clearState()
}

clearState = ()=>{
  this.setState({
    currentCertificateName: '',
    currentFile: null,
    fileSubmitted: false,
    isEditing: false,
    isReuploading: false,
    selectedCertificateId: 0,
    selectedAttachmentId: 0,
    currentUploadDate: '',
    selectedFileType: '',
    editModeAdd: false,
    displaySummary: true,
    openIntroduction: false,
    introductionValueBox: true
  });
}

  getSchoolData = async (id: string) => {
    this.setState({
      loader:true
    })
    const header = {
      token: this.state.token,
    };

    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSchoolInformation = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/school_details/show/${id}`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
    this.setState({
      displaySummary: true
    })
  }

  clearTime(day: string, type: 'start' | 'end') {
    this.setState({
      workTimes: {
        ...this.state.workTimes,
        [day]: {
          ...this.state.workTimes[day],
          [type]:{ hour: 0, minute: 0, period: 'AM'}
        },
      },
    } , ()=>{
      if (type == 'start') {
        this.saveStartTime(day, this.state.workTimes[day]?.start)
      }else{
        this.saveStartTime(day, this.state.workTimes[day]?.end )
      }
    });
  }
  // Customizable Area End
}
