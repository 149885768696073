// Customizable Area Start
export const imgPasswordVisible = require('../assets/ic_password_visible.png');
export const imgPasswordInVisible = require('../assets/ic_password_invisible.png');
export const imgHeaderBg = require('../assets/header_bg.png');
export const companyLogo = require('../assets/microsoft-logo.png');
export const profilepagebg = require('../assets/image_Header.jpg');
export const people1 = require('../assets/people1.png');
export const people2 = require('../assets/people2.png');
export const people3 = require('../assets/people3.png');
export const videobg = require('../assets/videobg.png');
export const profilebg = require('../assets/profile_bg.png');
export const profile = require('../assets/profile.png');
export const apple = require('../assets/apple.png');
export const loadingGif = require('../assets/loading.gif');
export const cardBackgroundImage = require('../assets/card_background.png');
export const right_arrow = require('../assets/right-arrow.png');
export const add_icon = require('../assets/add.png')
export const dots = require('../assets/Right_Button.png')
export const search = require('../assets/search.png')
export const image = require('../assets/1.png')
export const description = require('../assets/description.png')
export const location = require('../assets/location_on.png')
export const button_ = require('../assets/button_.png')
export const unchecked = require('../assets/checkbox_(1).png')
export const checked = require('../assets/checkbox_.png')
export const calendar = require('../assets/calendar_month.png')
export const experienceLogo = require('../assets/experience_logo.png');
export const educationLogo = require('../assets/education_logo.png');
export const locationLogo = require('../assets/location_logo.png');
export const unSavedStarIcon = require('../assets/unsaved_star.png')
export const savedStarIcon = require('../assets/saved_star.png')
export const rightArrow = require('../assets/arrow_right.png')
export const tickIcon = require('../assets/tick_icon.png')
export const applied_checkmark_circle = require('../assets/applied_checkmark_circle.png')
export const savedStarMobileIcon = require('../assets/saved_icon_mobile.png')
export const unSavedStarMobileIcon = require('../assets/unSaved_icon_mobile.png')
export const backIcon = require('../assets/backIcon.png')
export const noResult = require("../assets/Usability testing-pana (1) 1.png")
export const logo = require("../assets/PCC_Tree.png")
export const state = require("../assets/state.json")
export const profilePic = require("../assets/profilePic.png")
export const pdfIcon = require("../assets/pdf.png")
export const profileDummy = require("../assets/people1.png")
export const notFound = require("../assets/notFound.png")
export const doc = require("../assets/doc.png")
export const pcc = require("../assets/pcc.png")

// icons
export const icons = {
  back: 'chevron-left',
  business: 'business',
  photo_camera: 'photo-camera',
  edit: 'edit',
  check: 'check',
  share: 'share',
  more_vert: 'more-vert',
  location_on: 'location-on',
  close: 'close',
  search: 'search',
  date_range: 'date-range',
  navigate_next: 'navigate-next',
  videocam: 'videocam',
  add_circle_outline: 'add-circle-outline',
  play_arrow: 'play-arrow',
  plus: 'plus',
  cached: 'cached',
  loop: 'loop',
  flash_off: 'flash-off',
  flash_on: 'flash-on',
  add: 'add',
  expand_more: 'expand-more',
  calendar: 'calendar'
};

// @chirag :don't make any change 
export const daysObj = [
  { working_day_id: 1, day_name: 'Monday' },
  { working_day_id: 2, day_name: 'Tuesday' },
  { working_day_id: 3, day_name: 'Wednesday' },
  { working_day_id: 4, day_name: 'Thursday' },
  { working_day_id: 5, day_name: 'Friday' },
  { working_day_id: 6, day_name: 'Saturday' },
  { working_day_id: 7, day_name: 'Sunday' }
];

// Customizable Area End
