// Customizable Area Start
import React from "react";
import VerificationController, {
    Props
} from "./VerificationController";

import {
    Box,
    Button,
    Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
export const configJSON = require("./config");
import { wholeBackgroundImage, wholeBackgroundImageforMobile, email, phone, back, forward } from "./assets";
// Customizable Area End

export default class Verification extends VerificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <MainBox>
                <InnerBoxOne>
                    <BackButton data-test-id="handleback" onClick={this.handleBack}>
                        <div style={{ width: "24px", height: "24px" }}>
                            <img src={back} />
                        </div>
                        <BackTypography>{configJSON.back}</BackTypography>
                    </BackButton >
                    <HeadingOne>{configJSON.mainHeading}</HeadingOne>
                    <HeadingTwo>{configJSON.subHeading}</HeadingTwo>
                    
                    <EmailBox data-test-id="setEmail" onClick={this.handleTypeEmail} style={{borderImage: this.state.selectedtype=="email"? "linear-gradient(to right, #C6DC68, #395169) 8":"none"}}>
                        <Box style={{ display: "flex", gap: 16 }}>
                            <img src={email} />
                            <EmailTypography>{configJSON.email}</EmailTypography>
                        </Box>
                        <img src={forward} />
                    </EmailBox>
                    <PhoneBox data-test-id="setPhone" onClick={this.handleTypePhone} style={{borderImage: this.state.selectedtype=="phone"? "linear-gradient(to right, #C6DC68, #395169) 8":"none"}}>
                        <Box style={{ display: "flex", gap: 16 }}>
                            <img src={phone} />
                            <EmailTypography>{configJSON.phone}</EmailTypography>
                        </Box>
                        <img src={forward} />
                    </PhoneBox>
                    <ButtonContinue fullWidth data-test-id="continue" onClick={this.handleClick}>{configJSON.continue}</ButtonContinue>
                </InnerBoxOne>
            </MainBox>
            // Customizable Area End
        );
    }
}
// Customizable Area Start

export const MainBox = styled(Box)({
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: "100vh",
    position: "relative",
    backgroundSize: 'cover',
    background: `url(${wholeBackgroundImage})`,
    "@media only screen and (max-width: 502x)": {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        background: `url(${wholeBackgroundImageforMobile})`,
        height:"unset"
    },
});




const HeadingOne = styled(Typography)({
    color: '#1E293B',
    fontWeight: 700,
    lineHeight: '40px',
    marginTop: '56px',
    fontFamily: "Inter",
    textAlign: 'left' as 'left',
    fontSize: '30px',
    '@media only screen and (max-width: 502px)': {
        lineHeight: '32px',
        fontSize: '24px',
        marginTop:"129px"
    },
})
const HeadingTwo = styled(Typography)({
    color: '#334155',
    fontSize: '16px',
    marginBottom: '48px',
    lineHeight: '24px',
    fontFamily: "Inter",
    fontWeight: 400,
    textAlign: 'left' as 'left',
    '@media only screen and (max-width: 502px)': {
        marginTop:"8px",
        fontSize:"14px",
        lineHeight:"22px"
    },
})

const HeadingBox = styled(Box)({
    marginTop:"48px",
    '@media only screen and (max-width: 502px)': {
      
        marginTop:"96px"
    },

})

const ButtonContinue = styled(Button)({
    background: "#395169",
      color: "#FFFFFF",
      borderRadius: "8px",
      textTransform: "none" as "none",
      height: "56px",
      fontFamily:"Inter",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      width: "100%",
      marginTop: "48px",
    backgroundColor: '#395169',
    boxShadow: 'none',
    'font-weight': '700',
    '&:hover': {
      backgroundColor: '#395169',
      boxShadow: 'none',
    },
      "@media only screen and (max-width: 474px)": {
        marginTop:"60px"
       },
  })



const InnerBoxOne = styled(Box)({
    width: "384px",
    '@media only screen and (max-width: 439px)': {
        width: "327px"
    },
    '@media only screen and (max-width: 341px)': {
        width: "278"
    },
})
const EmailBox = styled(Box)({
    border: "1px solid #CBD5E1",
    cursor:"pointer",
    borderRadius: "8px",
    padding: "16px 20px",
    display: "flex",
    justifyContent: "space-between",
    borderImage: "linear-gradient(to right, #C6DC68, #395169) 8",
    '@media only screen and (max-width: 502px)': {
        lineHeight: '32px',
        fontSize: '24px',
        marginTop:"129px"
    }
})
const EmailTypography = styled(Typography)({
    fontWeight: 500,
    fontFamily: "Inter",
    fontSize: "18px",
    color: "#1E293B",
    lineHeight: "26px",
    '@media only screen and (max-width: 502px)':{
        lineHeight:"24px",
        fontSize:"16px",
    }
})
const PhoneBox = styled(Box)({
    display: "flex",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    cursor:"pointer",
    justifyContent: "space-between",
    marginTop: "24px",
    padding: "16px 20px",
    '@media only screen and (max-width: 502px)':{
        lineHeight:"24px",
        fontSize:"16px",
    }
})
const BackButton = styled(Box)({
    cursor:"pointer",
    top: 60,
    gap: "6px",
    display: "flex",
    position: "absolute" as "absolute",
    left: 120,
    "@media only screen and (max-width: 622px)": {
        left: 24
    },
})
const BackTypography = styled(Typography)({
    display: "contents",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1E293B",
    fontFamily: "Inter",
    "@media only screen and (max-width: 622px)": {
        display: "none"
    },
})
const webStyle = {
    createBtn: {
        marginBottom: '96px',
        color: '#FFFFFF',
        fontWeight: 700,
        marginTop: '48px',
        lineHeight: '24px',
        fontFamily: "Inter",
        fontSize: '16px',
        textTransform: 'none' as 'none',
        background: '#395169',
        height: '56px',
        borderRadius: '8px',
        widht: '100%',
    },
}

// Customizable Area End
